import {AppInsights} from '@config';
import {AppEvent, IEventTracker} from '@lib/infrastructure';

export const eventTracker = (): IEventTracker => ({
  trackEvent: (event: AppEvent) => {
    AppInsights.trackEvent({name: event.name, properties: {...event.properties, appType: 'UserApp'}});
    return Promise.resolve();
  },
  trackPageViewed: (event: AppEvent) => {
    AppInsights.trackPageView({name: event.name, properties: {...event.properties, appType: 'UserApp'}});
    return Promise.resolve();
  },
  trackError: (error: Error) => {
    AppInsights.trackException({
      exception: error,
    });

    return Promise.resolve();
  },
});
