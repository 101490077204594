import {useModal} from '@hooks';
import {Tile} from '../Tile';
import {format} from 'date-fns';
import styled from 'styled-components';
import {ConnectionUserDto, WorkspaceReservationDto} from '@lib/infrastructure';
import {Avatar} from '@molecules';
import {ConnectionOnMapModal} from './ConnectionOnMapModal';
import {useRef} from 'react';
import {StyledModalPage} from '../../bookWorkspace/BookWorkspaceCard/styles';
import {ConnectionOverview} from './ConnectionsOverview';
import {NoConnections, NoOneThere} from './EmptyState';
import {useRelevantConnections} from './useRelevantConnections';

export const ConnectionsTile = ({date}: {date: Date}) => {
  const {openModal, closeModal, setModalPages} = useModal();
  const dateKey = format(date, 'yyyy-MM-dd');

  const {connections, status} = useRelevantConnections(dateKey);

  const onViewAll = () => {
    setModalPages([
      <StyledModalPage
        title="Connections at the office"
        onClose={closeModal}>
        <div className="p-4">
          <ConnectionOverview dateKey={dateKey} />
        </div>
      </StyledModalPage>,
    ]);
    openModal();
  };

  return (
    <Tile
      title="Connections at the office"
      viewAllAction={status === 'Done' ? onViewAll : undefined}>
      <ol className="overflow-x-scroll flex gap-3 w-full">
        {status === 'Loading' ? (
          <ConnectionSkeletons count={5} />
        ) : status === 'UserHasNoConnections' ? (
          <NoConnections />
        ) : status === 'NoOneThere' ? (
          <NoOneThere />
        ) : (
          connections.map((c) => (
            <ConnectionBadge
              key={c.user.id}
              user={c.user}
              workspace={c.workspace}
            />
          ))
        )}
      </ol>
    </Tile>
  );
};

const ConnectionBadge = ({user, workspace}: {user: ConnectionUserDto; workspace: WorkspaceReservationDto | null}) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const {setModalPages, setBigPages, openModal} = useModal();

  const showConnectionOnMap = () => {
    if (!workspace) return;

    const mapView = (
      <ConnectionOnMapModal
        user={user}
        workspace={workspace}
      />
    );

    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  };

  return (
    <div
      className="flex flex-col gap-1 w-20 items-center text-center cursor-pointer p-4 rounded-md hover:bg-mapiq-black-150 focus-within:bg-mapiq-black-150"
      onClick={() => btnRef.current?.click()}>
      <Avatar
        shouldShowCheckIn={
          true /* Note: Should we not show check-in status if we're looking at tomorrow's reservations? */
        }
        user={user}
        size={'xlarge'}
        className="size-16"
      />
      <button
        ref={btnRef}
        className="overflow-ellipsis w-full overflow-hidden max-h-[2lh] outline-none"
        disabled={!workspace}
        onClick={showConnectionOnMap}>
        <strong>{user.name}</strong>
      </button>
      {workspace && workspace.areaName && (
        <div className="overflow-ellipsis w-full truncate text-mapiq-black-400">{workspace.areaName}</div>
      )}
    </div>
  );
};

const ConnectionSkeletons = ({count}: {count: number}) =>
  Array.from({length: count}, (_, i) => ({delay: i * 200})).map(({delay}) => (
    <ConnectionSkeleton
      key={delay}
      delay={delay}
    />
  ));

const ConnectionSkeleton = ({delay}: {delay: number}) => {
  return (
    <div className="flex flex-col gap-1 w-20 items-center p-4">
      <DelayedPulseBlock
        $delay={delay}
        className="bg-slate-100 animate-pulse rounded-full size-16 mb-2"
      />
      <DelayedPulseBlock
        $delay={delay}
        className="bg-slate-100 animate-pulse rounded-sm w-full h-4"
      />
      <DelayedPulseBlock
        $delay={delay}
        className="bg-slate-100 animate-pulse rounded-sm w-3/4 h-4"
      />
      <DelayedPulseBlock
        $delay={delay}
        className="bg-slate-100 animate-pulse rounded-sm w-2/3 h-4"
      />
    </div>
  );
};

const DelayedPulseBlock = styled.div<{$delay: number}>`
  // Note: using styled component here because struggling with tailwind dynamic class names
  animation-delay: ${({$delay}) => `${$delay}ms !important`};
  transition-delay: ${({$delay}) => `${$delay}ms !important`};
`;
