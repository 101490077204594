import {addMinutes, isAfter, isWithinInterval, min, setHours, startOfDay} from 'date-fns';

export type MeetingLike = {startDateTime: string; endDateTime: string; isAllDay: boolean};

export const selectMeetingsToShow = <TMeeting extends MeetingLike>(
  sortedMeetings: TMeeting[],
  time: Date,
  options: {allDayUntilHour: number; onGoingIfStartedMaxMinutesAgo: number},
): TMeeting[] => {
  const allDayCutOff = setHours(startOfDay(time), options.allDayUntilHour);

  const allDayMeetings = isAfter(allDayCutOff, time) ? sortedMeetings.filter((m) => m.isAllDay) : [];
  const justStartedMeetings = sortedMeetings.filter(
    (m) =>
      !m.isAllDay &&
      isWithinInterval(time, {
        start: new Date(m.startDateTime),
        end: min([
          new Date(m.endDateTime),
          addMinutes(new Date(m.startDateTime), options.onGoingIfStartedMaxMinutesAgo),
        ]),
      }),
  );
  const upcomingMeetings = sortedMeetings.filter((m) => !m.isAllDay && isAfter(new Date(m.startDateTime), time));
  const firstUpcomingMeetingStart = upcomingMeetings.at(0)?.startDateTime;
  const nextStartingMeetings = upcomingMeetings.filter((m) => m.startDateTime === firstUpcomingMeetingStart);

  return [...allDayMeetings, ...justStartedMeetings, ...nextStartingMeetings];
};
