import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '@hooks';
import {trackEvent} from '@lib/store';
import {SECTION_ID_FLOORS, constructHereAndNowExploreFilter, useFilterData} from '@lib/utils';
import {
  Resource,
  getBuildingResources,
  loadBuildingResources,
  transformResourcesToResourcesGroups,
} from '@lib/store/src/data/buildingResources';
import {resourceMatchesQuery} from '@lib/utils/src/util/search/resourceMatchesQuery';
import {SECTION_ID_RESOURCE_TYPE_FILTER} from '@lib/utils/src/util/filters/filterConstructors/resourceTypeFilter';
import uniqBy from 'lodash/uniqBy';
/*
  The setup of our Here & Now page does not really allow for elegant solutions in managing
  each tab's state...

  This hook is a consession; it makes it easier to pass state along between the list and map
  that are connected through the HereAndNow parent component.
*/
export function useExploreState(buildingId: string, initialQuery: string) {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  // Ensure we have a list of all resources for building ready:
  const resourceData = useAppSelector((state) => getBuildingResources(state, buildingId));

  const floorIds = useMemo(() => resourceData?.floordIds ?? [], [resourceData?.floordIds]);
  const allResources = useMemo(() => resourceData?.resources ?? [], [resourceData?.resources]);
  const resourceGroups = useMemo(() => transformResourcesToResourcesGroups(t, allResources), [allResources, t]);

  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);
  const [spotlightedResource, setSpotlightedResource] = useState<Resource | null>(null);

  const loadResources = useCallback(() => {
    if (buildingId) {
      dispatch(loadBuildingResources(buildingId));
    }
  }, [dispatch, buildingId]);

  useEffect(
    function initialLoad() {
      if (!resourceData) loadResources();
    },
    [loadResources, resourceData],
  );

  // Set up filters
  const floorsWithResources = useMemo(
    () =>
      uniqBy(
        resourceData?.resources
          ? resourceData?.resources.map((rsData) => ({id: rsData.floorId, name: rsData.floorName}))
          : [],
        'id',
      ),
    [resourceData?.resources],
  );

  const exploreFilters = useMemo(
    () => constructHereAndNowExploreFilter(floorsWithResources, t),
    [floorsWithResources, t],
  );
  const {filteredData: filteredResources, selectedFilters} = useFilterData(
    'HereAndNow_Explore',
    buildingId,
    allResources,
    exploreFilters,
    'and',
    false,
  );

  const isFloorFilterSelected = !!selectedFilters[SECTION_ID_FLOORS]?.length;
  const hasFilterSelected = Object.values(selectedFilters).some((filters) => !!filters?.length);

  // Set up search logic
  const [rawQuery, setRawQuery] = useState(initialQuery);

  const [query, setQuery] = useState('');
  const hasSearchQuery = !!query;

  // for explore we either want to have a highlighted state for the filtered resources
  // or the resources that match the query
  const highlightedResources = useMemo(() => {
    return filteredResources.filter((r) => resourceMatchesQuery(r, query));
  }, [query, filteredResources]);

  const buildingIdOfSelectedResource = selectedResource?.buildingId;
  useEffect(
    function ensureSelectionIsInBuilding() {
      if (buildingIdOfSelectedResource !== buildingId) {
        setSelectedResource(null);
      }
    },
    [buildingIdOfSelectedResource, buildingId],
  );

  // Set up floor id behavior
  const [floorId, setFloorId] = useState<string | null>(null);
  useEffect(
    function autoSelectFloor() {
      // Selections always dictate floor
      if (selectedResource) {
        setFloorId(selectedResource.floorId);
      } else {
        // When there is no selection, the first search/filter/fetched result sets the floor id...
        const candidate = isFloorFilterSelected
          ? filteredResources.at(0)?.floorId ?? null
          : highlightedResources.at(0)?.floorId || allResources.at(0)?.floorId || null;

        setFloorId((prev) => {
          // ...but _only_ if it's not currently set
          if (!prev || !floorIds.includes(prev) || isFloorFilterSelected) return candidate;

          return prev;
        });
      }
    },
    [selectedResource, highlightedResources, filteredResources, allResources, floorIds, isFloorFilterSelected],
  );

  useEffect(
    function clearSelectionIfNotInFilters() {
      const floorIdOfCurrentSelection = selectedResource?.floorId;
      if (floorIdOfCurrentSelection && !filteredResources.some((r) => r.floorId === floorIdOfCurrentSelection)) {
        setSelectedResource(null);
      }
    },
    [filteredResources, selectedResource?.floorId],
  );

  useEffect(() => {
    dispatch(trackEvent('HereAndNow_Explore__Search', {buildingId, query, resultCount: highlightedResources.length}));
  }, [query, highlightedResources, dispatch, buildingId]);

  return {
    buildingId,
    floorId,
    loadResources,

    resourceData,
    allResources,
    resourceGroups,
    filteredResources,
    highlightedResources,

    selectedResource,
    setSelectedResource,

    spotlightedResource,
    setSpotlightedResource,

    activeTypeFilters: selectedFilters[SECTION_ID_RESOURCE_TYPE_FILTER] ?? [],

    query,
    setQuery,
    rawQuery,
    setRawQuery,

    hasSearchQuery,
    hasFilterSelected,
  };
}

export type ExploreState = ReturnType<typeof useExploreState>;
