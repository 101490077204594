import {differenceInMinutes, differenceInSeconds} from 'date-fns';
import {useState} from 'react';

export const useRefreshDataIfStale = (isLoading: boolean, maxAgeSeconds: number) => {
  const [lastRefreshTriggered, setLastRefreshTriggered] = useState<Date | null>(null);

  const lastRefreshAgeMinutes =
    lastRefreshTriggered === null ? 0 : differenceInMinutes(new Date(), lastRefreshTriggered);

  // TODO: translate
  const lastRefreshLabel = isLoading
    ? 'updating...'
    : lastRefreshAgeMinutes === 0
    ? 'updated just now'
    : `updated ${lastRefreshAgeMinutes} min. ago`;

  const refreshDataIfStaleAndNotLoading = (update: Function) => {
    if (isLoading) return;

    // If data goes stale, trigger a reload and store the last reload trigger timestamp
    const dataAge = lastRefreshTriggered === null ? Infinity : differenceInSeconds(new Date(), lastRefreshTriggered);
    const dataWentStale = dataAge > maxAgeSeconds;

    if (dataWentStale) {
      update();
      setLastRefreshTriggered(() => new Date());
    }
  };

  return {
    refreshIfStale: refreshDataIfStaleAndNotLoading,
    refreshLabel: lastRefreshLabel,
  };
};
