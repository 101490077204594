import {
  useAppDispatch,
  useAppSelector,
  useBreakPoint,
  useIsPageActive,
  useRefreshDataIfStale,
  useRoomImage,
  useScheduleAndSensorStatuses,
} from '@hooks';
import {
  withAsyncThunkErrorHandling,
  getWorkdayByDate,
  getUser,
  getIsWorkdayLoading,
  MeetingRoom,
  loadAllMeetingRooms,
  getAllMeetingRoomSuggestions,
  getMeetingRoomSuggestionByBuildingIdLoadingStatus,
} from '@lib/store';
import {AvailableRoomsSkeletonLoader, HaNScheduleDataIcon, Tile} from '@organisms';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import startOfMinute from 'date-fns/startOfMinute';
import {useEffect} from 'react';
import {DEFAULT_NEW_EVENT_DURATION, REFRESH_INTERVAL_SECONDS} from '@constants';
import {useTranslation} from 'react-i18next';
import {cn} from '@utils';

export const AvailableRoomsTile = ({time}: {time: Date}) => {
  const dispatch = useAppDispatch();

  const pageActive = useIsPageActive();
  const {id: loggedInUserId} = useAppSelector(getUser);

  const dateKey = format(time, 'yyyy-MM-dd');
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey)) ?? null;
  const workdayLoading = useAppSelector(getIsWorkdayLoading);

  const buildingId = workday?.nodeId;
  const allRooms = useAppSelector((state) => getAllMeetingRoomSuggestions(state, buildingId ?? ''));
  const meetingRoomsAreLoading = useAppSelector(
    (state) => getMeetingRoomSuggestionByBuildingIdLoadingStatus(state, buildingId ?? '') === 'loading',
  );
  const {refreshIfStale, refreshLabel} = useRefreshDataIfStale(meetingRoomsAreLoading, REFRESH_INTERVAL_SECONDS);
  const isLoading = workdayLoading || meetingRoomsAreLoading;
  const newStartDateTime = startOfMinute(time);
  const newEndDateTime = addMinutes(newStartDateTime, DEFAULT_NEW_EVENT_DURATION);

  useEffect(
    function loadAvailableRooms() {
      if (!buildingId || !pageActive) return;
      refreshIfStale(() => {
        dispatch(
          withAsyncThunkErrorHandling(() =>
            loadAllMeetingRooms({
              startDateTime: newStartDateTime.toISOString(),
              endDateTime: newEndDateTime.toISOString(),
              buildingId: buildingId,
              internalAttendeeIds: [loggedInUserId],
              totalAttendees: 1,
            }),
          ),
        );
      });
    },
    [buildingId, dispatch, loggedInUserId, newEndDateTime, newStartDateTime, pageActive, refreshIfStale, time],
  );

  return (
    <Tile title="Available rooms">
      {isLoading ? (
        <AvailableRoomsSkeletonLoader columns={3} />
      ) : (
        <div className="grid grid-cols-3 gap-8">
          {buildingId
            ? allRooms.slice(0, 3).map((room) => (
                <AvailableRoomTileRow
                  key={room.id}
                  room={room}
                  startTime={newStartDateTime.toISOString()}
                  endTime={newEndDateTime.toISOString()}
                  buildingId={buildingId}
                />
              ))
            : null}
        </div>
      )}
    </Tile>
  );
};

const AvailableRoomTileRow = ({
  room,
  startTime,
  endTime,
  buildingId,
}: {
  room: MeetingRoom;
  startTime: string;
  endTime: string;
  buildingId: string;
}) => {
  const {t} = useTranslation();
  const bp = useBreakPoint();

  const {floorName, displayName, capacity, email, roomPictureHash} = room;
  const {scheduleStatus, sensorStatus} = useScheduleAndSensorStatuses(room, startTime, endTime);
  const imageURL = useRoomImage(
    email || displayName,
    roomPictureHash,
    buildingId,
    Math.round(48 * window.devicePixelRatio * 4),
  );

  return (
    <div className="flex flex-col gap-2">
      <img
        alt={room.displayName}
        src={imageURL}
        className={cn(`object-cover rounded-md w-full aspect-[255/150]`, {'aspect-square': bp === 'medium'})}
      />
      <div
        className={cn('flex flex-row justify-between gap-2 items-center', {
          'flex-col-reverse items-start': bp === 'medium',
        })}>
        <div className="flex flex-col">
          <h4>{displayName}</h4>
          <p>
            {t('meeting:MeetingRoomBasicInfo', {
              count: capacity,
              floorName,
            })}
          </p>
        </div>
        <div className="flex flex-row gap-2">
          {sensorStatus && (
            <HaNScheduleDataIcon
              data-testid={`HomePageSensorIcon-${floorName}`}
              status={sensorStatus}
              icon="broadcast"
            />
          )}
          {scheduleStatus && (
            <HaNScheduleDataIcon
              data-testid={`HomePageScheduleDataIcon-${floorName}`}
              status={scheduleStatus}
              icon="calendar"
            />
          )}
        </div>
      </div>
    </div>
  );
};
