import {addDays, format, isBefore, parseISO, set, startOfDay} from 'date-fns';
import {useCallback, useEffect, useMemo, useState} from 'react';
/*
  Logic for determining the part of day based on any time. Part of day will be used to switch
  on-and-off sections of the home page to ensure relevancy.
*/
export type PartOfDay = 'WorkingHours' | 'Evening';
type PartOfDayRange = {to: [number, number] | null; part: PartOfDay};

const ranges: PartOfDayRange[] = [
  {to: [18, 0], part: 'WorkingHours'},
  {to: null, part: 'Evening'},
];

const partOfDayForTime = (time: Date): PartOfDay => {
  const base = startOfDay(time);

  const range = ranges.find(({to, part}) => {
    // A `to` value of null marks end of day
    if (!to) return true;

    const [hours, minutes] = to;
    const end = set(base, {hours, minutes});
    return isBefore(time, end);
  });

  if (!range) throw new RangeError(`range constant invalid; no range found for time ${format(time, 'HH:mm')}`);

  return range.part;
};

/*
    Logic for creating a self-updating date instance that allows for easy debug-overrides
  */
// Note: we should not export this; we want there to only be one Home time that is
// in sync with all components. That means we either pass it along or put it in Context
// if that gets cumbersome. (or make it global...)

export type ComponentTime = ReturnType<typeof useHomeTime>;

const DELTA_T = 1 * 1000;

export const useHomeTime = (dt = DELTA_T) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  const [debugTime, setDebugTimeInternal] = useState<null | Date>(null);
  const usesDebugTime = debugTime != null;
  const setDebugTime = useCallback((t: Date | null) => {
    setDebugTimeInternal(t);

    // Ensure no delay when switching back to live time
    if (t === null) {
      setCurrentTime(new Date());
    }
  }, []);

  useEffect(
    function scheduleTimeUpdate() {
      if (usesDebugTime) return;

      const intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, dt);

      return () => clearInterval(intervalId);
    },
    [usesDebugTime, dt],
  );

  // There're a bunch of computed values based on the time
  const time = debugTime ?? currentTime;
  const timeDisplay = format(time, 'HH:mm');
  const todayKey = format(time, 'yyyy-MM-dd');
  const tomorrowKey = format(addDays(time, 1), 'yyyy-MM-dd');
  const partOfDay = partOfDayForTime(time);

  // For unoptimized selectors, we memo start of day
  const todayDate = useMemo(() => startOfDay(parseISO(todayKey)), [todayKey]);
  const tomorrowDate = useMemo(() => startOfDay(parseISO(tomorrowKey)), [tomorrowKey]);

  return {
    time,
    timeDisplay,
    partOfDay,

    todayKey,
    tomorrowKey,
    todayDate,
    tomorrowDate,

    usesDebugTime,
    setDebugTime,
  };
};
