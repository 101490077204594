import {ConnectionData} from '../../../../../../submodules/map/mapiq-map/markers/NodeMarkerData';
import {MapViewHighlight} from './types';

export const highlightsAreEqual = (h1: MapViewHighlight[], h2: MapViewHighlight[]) => {
  if (h1.length !== h2.length) return false;
  if (h1 === h2) return true;

  const s1 = new Set(h1.map(highlightHash));

  return h2.every((h) => s1.has(highlightHash(h)));
};

const highlightHash = (h: MapViewHighlight): string => {
  switch (h.type) {
    case 'area':
    case 'desk':
      return `${h.nodeId}_${h.available}_${
        h.connections
          ? h.connections
              .map(userDataHash)
              .sort((a, b) => a.localeCompare(b))
              .join(`_`)
          : ''
      }`;
    case 'facility':
      return h.priority ? `${h.nodeId}_${h.priority}` : h.nodeId;
    case 'room':
    case 'sensor_status':
      return h.nodeId;
    case 'sensor':
      return `${h.nodeId}_${h.nrPresent}_${h.nrTentative}_${h.nrUnknown}`;
    case 'bookable_desk_sensor':
      return `${h.nodeId}_${h.nrPresent}_${h.nrTentative}_${h.nrUnknown}_${h.booked}_${
        h.connection ? userDataHash(h.connection) : 'anon'
      }`;
  }
};

const userDataHash = (u: ConnectionData) => {
  return `${u.initials}_${u.color}_${u.picture}`;
};
