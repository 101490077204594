import {useAppSelector, useBreakPoint, useFeatureToggle} from '@hooks';
import {Extra, getExtras} from '@lib/store';
import {ExtrasOverview, ConnectionOverview} from '@organisms';
import {format} from 'date-fns';

/**
 * This sidebar takes care of its own content. It tries to show content as soon as it's available, but takes care to only _append_ content.
 * This prevents vertical layout shifts that might cause the thing users are looking at / interacting with to move down.
 */
export const Sidebar = ({date}: {date: Date}) => {
  const bp = useBreakPoint();
  const dateKey = format(date, 'yyyy-MM-dd');

  const {ExtrasFeature: extrasEnabled} = useFeatureToggle();
  const extrasLoading = useAppSelector((state) => state.data.extras.isLoading);

  const relevantExtras = useAppSelector(getExtras)
    .filter((e) => e.type === 'Workday' || e.type === 'UserProfile')
    // Note: should we respect server-side ordering instead?
    .sort(workdayFirstAlphabetical);

  // On mobile, connections section gets its own tile
  // On wider breakpoints, only show it once extras have loaded
  const showConnections = bp !== 'small' && !extrasLoading;

  return (
    <>
      {extrasEnabled ? (
        <ExtrasOverview
          extras={relevantExtras}
          loading={extrasLoading}
        />
      ) : null}
      {showConnections ? <ConnectionOverview dateKey={dateKey} /> : null}
    </>
  );
};

const workdayFirstAlphabetical = (e1: Extra, e2: Extra) => {
  if (e1.type === 'Workday' && e2.type !== 'Workday') return -1;
  if (e2.type === 'Workday' && e1.type !== 'Workday') return 1;

  return e1.name.localeCompare(e2.name);
};
