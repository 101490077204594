import {AreaWithSensorInfo} from '@lib/store';
import {HaNWorkspaceInformation, HaNOccupancyInformation} from '@organisms';
import {useState} from 'react';

type HaNWorkspaceModalProps = {
  onClose: () => void;
  workspace: AreaWithSensorInfo;
};

export type HaNWorkspaceModalPage = 'workspaceInformation' | 'availabilityInformation';

export const HaNWorkspaceModal = ({onClose, workspace}: HaNWorkspaceModalProps) => {
  const [page, setPage] = useState<HaNWorkspaceModalPage>('workspaceInformation');
  const [previousPage, setPreviousPage] = useState<HaNWorkspaceModalPage>('workspaceInformation');

  function handleOnBack() {
    setPage('workspaceInformation');
    setPreviousPage('availabilityInformation');
  }

  return (
    <>
      <HaNWorkspaceInformation
        onClose={onClose}
        workspace={workspace}
        setPage={setPage}
      />
      <HaNOccupancyInformation
        page={page}
        onClose={onClose}
        onBack={handleOnBack}
      />
    </>
  );
};
