import {useAppDispatch, useAppSelector, useBreakPoint, useIsPageActive, useRefreshDataIfStale} from '@hooks';
import {WorkspaceOccupancyState} from '@lib/infrastructure';
import {
  getAreaById,
  getAreaSensorInfo,
  getWorkdayByDate,
  getWorkspaceOccupancyForBuildingId,
  getWorkspaceOccupancyLoadingStatusForBuildingId,
  loadWorkspaceOccupancy,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {pickUsingStringAsIndex} from '@lib/utils';
import bg_03 from '@images/rooms/03.webp';
import bg_09 from '@images/rooms/09.webp';
import bg_13 from '@images/rooms/13.webp';
import bg_14 from '@images/rooms/14.webp';
import bg_15 from '@images/rooms/15.webp';
import bg_18 from '@images/rooms/18.webp';
import bg_19 from '@images/rooms/19.webp';
import {HaNWorkspaceIcon, Tile} from '@organisms';
import {format} from 'date-fns';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {cn} from '@utils';

const REFRESH_INTERVAL_SECONDS = 5 * 60;

export const AvailableWorkspacesTile = ({time}: {time: Date}) => {
  // Note that in this component { time } is used for two things:
  //  - determining which workday is relevant, which helps decide which building to show workspaces for
  //  - triggering re-renders so we can check if our data went stale
  // For the actual stale-checks, we don't use time but use in-line calls to new Date(),
  // this ensures our time-traveling features will not impact our data-loading

  const dispatch = useAppDispatch();
  const dateKey = format(time, 'yyyy-MM-dd');

  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey));
  const buildingId = workday?.nodeId;

  const availableWorkspaces = useAppSelector(getWorkspaceOccupancyForBuildingId(buildingId ?? ''))?.states ?? [];
  const loadingState = useAppSelector((state) =>
    getWorkspaceOccupancyLoadingStatusForBuildingId(state, buildingId ?? ''),
  );

  const availableWorkspacesLoading = loadingState === 'loading';

  const pageActive = useIsPageActive();
  const {refreshIfStale, refreshLabel} = useRefreshDataIfStale(availableWorkspacesLoading, REFRESH_INTERVAL_SECONDS);

  function loadWorkspaceAvailability() {
    // List time as a dependency: even though we won't use it, its updates will dictate
    // the interval at which we check whether data went stale
    time;

    // Nothing to load if we don't have a building
    if (!buildingId) return;

    // Let's not refresh if this page is not being looked at by a user
    if (!pageActive) return;

    refreshIfStale(() => {
      dispatch(withAsyncThunkErrorHandling(() => loadWorkspaceOccupancy(buildingId)));
    });
  }

  /* Cannot be moved outside of useEffect because it will complain about faulty setState */
  useEffect(loadWorkspaceAvailability, [buildingId, time, dispatch, refreshIfStale, pageActive]);

  const workspacePicks = Array.from(availableWorkspaces)
    .sort((a, b) => availableCount(b) - availableCount(a))
    .slice(0, 3);

  return (
    <Tile title="Available workspaces">
      {loadingState === 'failed' ? (
        <div>Failed to load workspace availability information</div>
      ) : workspacePicks.length > 0 ? (
        <div className="grid grid-cols-3 gap-8 w-full">
          {workspacePicks.map((r) => (
            <WorkspaceItem
              key={r.node.id}
              workspace={r}
            />
          ))}
        </div>
      ) : loadingState === 'loaded' ? (
        <div>No workspace availability information available</div>
      ) : (
        <div className="grid grid-cols-3 gap-8 w-full">
          <WorkspaceItemSkeleton />
          <WorkspaceItemSkeleton />
          <WorkspaceItemSkeleton />
        </div>
      )}
    </Tile>
  );
};

const WorkspaceItem = ({workspace}: {workspace: WorkspaceOccupancyState}) => {
  const {t} = useTranslation();
  const bp = useBreakPoint();

  // TODO: Don't like having to query the shifts store for this... think it's only because we
  //       need to merge in capacity?
  const area = useAppSelector((state) => getAreaById(state, workspace.node.id));

  const imageUrl = pickUsingStringAsIndex(workspace.node.id, [bg_03, bg_09, bg_13, bg_14, bg_15, bg_18, bg_19]);
  const capacity = workspace.workplaceState.nrDesks;

  const capacityLabel = t('meeting:MeetingRoomSeat', {count: capacity});
  const floorLabel = workspace.node.parents.Floor.displayName;
  const sensorInfo = getAreaSensorInfo(t, area, workspace);

  return (
    <div className="flex flex-col gap-4">
      <img
        alt={`Placeholder image for workspace`}
        src={imageUrl}
        className={cn(`object-cover rounded-md aspect-[255/150]`, {'aspect-square': bp === 'medium'})}
      />
      <div
        className={cn(`flex gap-2 justify-between flex-row items-center`, {
          'flex-col-reverse items-start': bp === 'medium',
        })}>
        <div>
          <strong>{workspace.node.displayName}</strong>
          <p>
            {capacityLabel}, {floorLabel}
          </p>
        </div>
        {sensorInfo && (
          <div className="flex-none">
            <HaNWorkspaceIcon iconType={sensorInfo.status} />
          </div>
        )}
      </div>
    </div>
  );
};

const WorkspaceItemSkeleton = () => {
  return (
    <div className="w-full flex-grow flex flex-col gap-4">
      <div className={`animate-pulse duration-1500 rounded-md bg-slate-100 w-full aspect-[255/150]`}></div>
      <div className="flex flex-col gap-2">
        <div className="animate-pulse duration-1500 delay-500 rounded-md bg-slate-100 w-full h-4"></div>
        <div className="animate-pulse duration-1500 delay-500 rounded-md bg-slate-100 w-full h-4"></div>
      </div>
    </div>
  );
};

function availableCount(wpo: WorkspaceOccupancyState): number {
  return (
    wpo.workplaceState.nrDesks -
    (wpo.workplaceState.nrPresent + wpo.workplaceState.nrTentative + wpo.workplaceState.nrUnknown)
  );
}
