import {Icon} from '@atoms';
import {extras} from '@lib/locales';
import {H4} from '@quarks';
import {cn} from '@utils';
import {IconNames} from 'mapiq-atoms';
import {useTranslation} from 'react-i18next';

type Props = {
  iconName: IconNames;
  title: keyof typeof extras;
  actionIconName?: IconNames;
  url: string;
};
export const ExtraTile = ({iconName, title, actionIconName = 'openInBrowser', url}: Props) => {
  const {t} = useTranslation();

  return (
    <a
      className={cn(`flex gap-4 items-center cursor-pointer w-full`)}
      target="_blank"
      href={url}>
      <div className="bg-beige-500* flex-none size-10 rounded-md flex items-center justify-center">
        <Icon
          icon={iconName}
          size="1.25rem"
        />
      </div>
      <H4 className="truncate flex-1">{t(`extras:${title}`)}</H4>
      <Icon
        className="flex-shrink-0"
        icon={actionIconName}
        color="#767676"
        size="20px"
      />
    </a>
  );
};
