import type {AreaWithSensorInfo, MeetingRoom} from '@lib/store';
import {FacilityResource} from '@lib/store/src/data/buildingResources';

export function isMeetingRoom(entity: AreaWithSensorInfo | MeetingRoom | FacilityResource): entity is MeetingRoom {
  return entity.type === 'meetingRoom';
}

export function isArea(entity: AreaWithSensorInfo | MeetingRoom | FacilityResource): entity is AreaWithSensorInfo {
  return entity.type === 'area';
}
