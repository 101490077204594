import {Illustration, Loader} from '@atoms';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {
  withAsyncThunkErrorHandling,
  loadAvailableMeetingRooms,
  getAvailableMeetingRooms,
  getMeetingUpdateLoadingStatus,
  MeetingRoom,
  getAllBuildings,
  getAllFloors,
} from '@lib/store';
import {ChipFilterNew} from '@molecules';
import {CreateEventRoomCellItem, ModalPage} from '@organisms';
import {FlexCol, P} from '@quarks';
import {parseISO} from 'date-fns';
import {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CreateEventManualRoomSelectionCardProps} from './types';
import {SECTION_ID_BUILDINGS, constructNewEventAndAdHocRoomFilter, useDebouncedEffect, useFilterData} from '@lib/utils';

export const CreateEventManualRoomSelectionCard = ({
  origin,
  defaultBuildingId,
  endDateTime,
  onClose,
  onSelect,
  selectedRooms,
  startDateTime,
  title = 'screen:AdHocRoomSelection',
}: CreateEventManualRoomSelectionCardProps) => {
  const {removePages, modalPages} = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const isUpdatingEvent = useAppSelector(getMeetingUpdateLoadingStatus) === 'Loading';

  const modalPageTitle = t(title);

  const availableRooms = useAppSelector(getAvailableMeetingRooms);

  const buildings = useAppSelector(getAllBuildings);
  const allFloors = useAppSelector(getAllFloors);
  const filters = useMemo(
    () => constructNewEventAndAdHocRoomFilter(buildings, defaultBuildingId, allFloors, t),
    [allFloors, defaultBuildingId, buildings, t],
  );

  const {filteredData, selectedFilters} = useFilterData(origin, defaultBuildingId, availableRooms, filters);

  const selectableRooms = filteredData.filter((meetingRoom) => !selectedRooms.includes(meetingRoom.email));

  const buildingFromFilters = selectedFilters[SECTION_ID_BUILDINGS]?.[0];

  useDebouncedEffect(
    useCallback(() => {
      if (!buildingFromFilters?.length) return;

      const loadRooms = async () => {
        setIsLoading(true);
        await dispatch(
          withAsyncThunkErrorHandling(() =>
            loadAvailableMeetingRooms({
              startDateTime: parseISO(startDateTime),
              endDateTime: parseISO(endDateTime),
              buildingId: buildingFromFilters,
            }),
          ),
        );

        setIsLoading(false);
      };

      loadRooms();
    }, [buildingFromFilters, dispatch, endDateTime, startDateTime]),
    300,
  );

  const handleSelectRoom = (room: MeetingRoom) => {
    onSelect(room);
  };

  return (
    <ModalPage
      onBack={modalPages.length === 1 ? undefined : () => removePages(1)}
      onClose={onClose}
      title={modalPageTitle}>
      <ChipFilterNew
        filterSlice={origin}
        testIdPrefix="HaNRoomFilters"
        areFilterTransparent
        goBackFromFilterModal={() => {
          removePages(1);
        }}
        closeFilterModal={onClose}
      />
      <FlexCol
        gap={16}
        paddingTop={16}>
        {isLoading ? (
          <FlexCol alignItems="center">
            <Loader />
          </FlexCol>
        ) : selectableRooms.length === 0 ? (
          <FlexCol alignItems="center">
            <Illustration
              illustration="noResults"
              size="100px"
              aria-label={''}
            />
            <P>{t('meeting:CouldntFindMeetingRooms')}</P>
          </FlexCol>
        ) : (
          selectableRooms.map((room) => (
            <CreateEventRoomCellItem
              key={room.id}
              onClose={onClose}
              room={room}
              disabled={isUpdatingEvent}
              onClick={() => handleSelectRoom(room)}
            />
          ))
        )}
      </FlexCol>
    </ModalPage>
  );
};
