import {useModal} from '@hooks';
import {Button} from '@molecules';
import {PropsWithChildren} from 'react';
import emptyStateImgUrl from '../../../../assets/images/empty-states/connections.svg';
import {AddConnectionsCard} from '../../connections';

const EmptyState = ({title, subTitle, children}: PropsWithChildren<{title?: string; subTitle: string}>) => {
  return (
    <div className="bg-beige-200 w-full p-8 rounded-lg flex flex-col gap-2 items-center text-center">
      <img
        src={emptyStateImgUrl}
        alt="Speech bubbles"
      />
      {title && (
        <p>
          <strong>{title}</strong>
        </p>
      )}
      <p className="mb-2 text-mapiq-black-500">{subTitle}</p>
      {children}
    </div>
  );
};
export const NoConnections = () => {
  const {addPages, openModal} = useModal();
  const handleOpenAddConnections = () => {
    addPages([<AddConnectionsCard />]);
    openModal();
  };

  return (
    <EmptyState
      title="See where your coworkers are"
      subTitle="Connect with your coworkers to see when and where they will be working">
      <Button
        aria-label="Add a connection"
        button="tertiary"
        iconRight="plus"
        onClick={handleOpenAddConnections}>
        Connect
      </Button>
    </EmptyState>
  );
};
export const NoOneThere = () => {
  return <EmptyState subTitle="None of your connections are here" />;
};
