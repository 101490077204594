import {Resource} from '@lib/store';
import {resourceMatchesQuery} from '@lib/utils';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {IconProps} from '@atoms';
import {TranslationService} from '@lib/store/src/@types/i18next';

export type CategoryConfig = {
  title: string;
  icon: IconProps['icon'];
  results: Resource[];
  buildingId: string;
  imageHash: string | null;
  onClick: () => void;
};

// Note: this was intended to just encode the priority, but it proved difficult to
// extract the type of a valid translation key...
const createExploreSections = (t: TranslationService) =>
  [
    {title: t('hereAndNow:ResourceFilterRooms'), icon: 'meetingRoom'},
    {title: t('hereAndNow:ResourceFilterAreas'), icon: 'desk'},
    {title: t('facilities:facilityType_Lounge'), icon: 'lounge'},
    {title: t('facilities:facilityType_Lockers'), icon: 'lock'},
    {title: t('facilities:facilityType_FoodAndDrinks'), icon: 'foodAndDrink'},
    {title: t('facilities:facilityType_Reception'), icon: 'reception'},
  ] as const;

export const useCategoryTiles = (buildingId: string, resources: Resource[]) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const resourceImages = new Map(
    resources.flatMap((r) => {
      if (r.type !== 'room') return [];
      if (!r.data.roomPictureHash) return [];

      return [[r.id, r.data.roomPictureHash]];
    }),
  );

  const onTileClick = (title: string) => {
    const query = new URLSearchParams();
    if (title) query.set('q', title);
    query.set('buildingId', buildingId);

    const url = `/now/explore?${query.toString()}`;
    navigate(url);
  };

  const exploreSuggestions: CategoryConfig[] = createExploreSections(t).map(({title, icon}) => {
    const results = resources.filter((r) => resourceMatchesQuery(r, title));

    let imageHash: string | null = null;
    for (const r of results) {
      const hash = resourceImages.get(r.id);
      if (hash) {
        imageHash = hash;
        break;
      }
    }

    return {
      title,
      icon,
      results,
      imageHash,
      buildingId,
      onClick: () => onTileClick(title),
    };
  });

  const relevantTiles = exploreSuggestions.filter(({results}) => {
    return results.length > 0;
  });

  return relevantTiles;
};
