import styled from 'styled-components';
import {ModalPage} from '../ModalPage';
import {ReactNode} from 'react';
import {useModal} from '@hooks';

export const BigMapModal = ({title, main, footer}: {title: string; main: ReactNode; footer: ReactNode}) => {
  const {closeModal} = useModal();

  return (
    <StyledModalPage
      onClose={closeModal}
      title={title}>
      <div className="flex flex-col h-full">
        <div className="flex-grow">{main}</div>
        <footer>{footer}</footer>
      </div>
    </StyledModalPage>
  );
};

const StyledModalPage = styled(ModalPage)`
  --modal-padding-x: 0;
  --modal-padding-y: 0;
`;
