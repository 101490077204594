import {Skeleton} from '@molecules';

export const AvailableRoomsSkeletonLoader = ({columns = 3}: {columns?: 1 | 2 | 3 | 4}) => {
  return (
    <div className="flex gap-8 w-full">
      {Array.from({length: columns}, (_, i) => (
        <div
          className="w-full flex-grow flex flex-col"
          key={i}>
          <div className="flex flex-col gap-4">
            <Skeleton className="bg-slate-100 w-full aspect-[255/150] " />
            <Skeleton className="bg-slate-100 w-full h-8" />
          </div>
        </div>
      ))}
    </div>
  );
};
