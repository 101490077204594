import toast from 'react-hot-toast';
import {useAppDispatch, useAppSelector, useBreakPoint, useModal} from '@hooks';

import {
  Meeting,
  MeetingRoom,
  getCanUserAddRoomToExistingMeeting,
  getDefaultBuildingId,
  getMeetingRoomsByMeetingId,
  getMeetingRoomsGroupedByBuilding,
  getMeetingsByDate,
  getMeetingsLoadingStatusByDate,
  getUserDirectoryObjectId,
  updateMeeting,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {Button, ExpandMapButton, IconButton} from '@molecules';
import {BigMapModal, CreateEventManualRoomSelectionCard, LoaderTile, MeetingDetailsCard, Tile} from '@organisms';
import {Icon} from '@atoms';
import {differenceInMinutes, format, isSameDay, isWithinInterval} from 'date-fns';
import {sortAttendeesByLocation} from '@utils';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {selectMeetingsToShow} from '../selectMeetingsToShow';
import {MeetingRoomInfoFooter} from './MeetingRoomInfoFooter';
import {MeetingRoomMapView} from './MeetingRoomMapView';

const UpcomingMeetingTile = ({meeting, time}: {meeting: Meeting; time: Date}) => {
  const {t} = useTranslation();
  const breakpoint = useBreakPoint();

  const addRoomLabel = 'Add room'; // TODO: translate

  const dispatch = useAppDispatch();

  // for handling clicks
  const {openModal, closeModal, setModalPages, setBigPages} = useModal();

  const meetingRooms = useAppSelector((state) => getMeetingRoomsByMeetingId(state, meeting.id));
  const location = meetingRooms.at(0);

  // TODO: move to util
  const startTime = new Date(meeting.startDateTime);
  const endTime = new Date(meeting.endDateTime);
  const singleDay = isSameDay(startTime, endTime);

  const labelFormat = singleDay ? 'HH:mm' : 'MM-dd HH:mm';

  const timeLabel = meeting.isAllDay
    ? 'All day'
    : `${format(startTime, labelFormat)} – ${format(endTime, labelFormat)}`;

  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const currentUserDirectoryObjectId = useAppSelector(getUserDirectoryObjectId);
  const currentUser = meeting.attendees.find(
    (attendee) => attendee.directoryObjectId === currentUserDirectoryObjectId,
  )!;

  const meetingRoomsByBuilding = useAppSelector((state) => getMeetingRoomsGroupedByBuilding(state, meeting.id));
  const attendeesGroupedByLocation = sortAttendeesByLocation(
    meeting.attendees,
    currentUser,
    defaultBuildingId,
    meetingRoomsByBuilding,
  );

  const canAddRoomParams = useMemo(() => ({meetingId: meeting.id}), [meeting]);
  const canUserAddRoom =
    useAppSelector((state) => getCanUserAddRoomToExistingMeeting(state, canAddRoomParams)) && !location;

  const inOfficeAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Local')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'office' as const,
        count: 0,
        primary: true,
      },
    );

  const remoteAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Remote')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'remote' as const,
        count: 0,
        primary: false,
      },
    );

  const unknownAttendees = attendeesGroupedByLocation
    .filter((g) => g.locationType === 'Unknown')
    .reduce(
      (acc, g) => ({...acc, count: acc.count + g.attendees.filter((a) => a.responseStatus !== 'Declined').length}),
      {
        icon: 'locationUnknown' as const,
        count: 0,
        primary: false,
      },
    );

  const declinedAttendees = {
    primary: false,
    icon: 'noCalendar' as const,
    count: meeting.attendees.filter((a) => a.responseStatus === 'Declined').length,
  };

  const attendeeTiles = [inOfficeAttendees, remoteAttendees, declinedAttendees, unknownAttendees];
  const showTiles = breakpoint !== 'small';
  const handleInfoClick = () => {
    const modalCard = (
      <MeetingDetailsCard
        date={meeting.startDateTime}
        meeting={meeting}
      />
    );
    setModalPages([modalCard]);
    openModal();
  };

  const handleAddRoomClick = () => {
    setModalPages([
      <CreateEventManualRoomSelectionCard
        defaultBuildingId={defaultBuildingId}
        origin="CalendarOverview_AdHocRoom"
        endDateTime={meeting.endDateTime}
        onClose={closeModal}
        onSelect={async (value: MeetingRoom) => {
          const {success} = await dispatch(
            withAsyncThunkErrorHandling(() =>
              updateMeeting({
                origin: 'AddRoomToExistingMeeting',
                meetingId: meeting.id,
                systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                rooms: [...meetingRooms.map((room) => room.email), value.email],
              }),
            ),
          );
          if (success) {
            closeModal();
            toast.success(t('meeting:AddMeetingRoomToMeetingSuccessMessage', {roomName: value.displayName}));
          }
        }}
        selectedRooms={meetingRooms.map((room) => room.email)}
        startDateTime={meeting.startDateTime}
      />,
    ]);
    openModal();
  };

  const timeToStart = differenceInMinutes(startTime, time);
  const isOngoing = isWithinInterval(time, {start: startTime, end: endTime});
  const hours = Math.floor(timeToStart / 60);
  const minutesLeft = timeToStart % 60;

  // TODO: translate
  const relativeTime = hours >= 2 ? `${hours} hours` : hours === 1 ? `1h ${minutesLeft}m` : `${timeToStart} minutes`;
  const tileTitle = isOngoing ? `Current meeting` : `Upcoming meeting (in ${relativeTime})`;

  const expandMap = () => {
    if (!location) return;
    const mapView = (
      <BigMapModal
        title="Meeting room"
        main={
          <MeetingRoomMapView
            room={location}
            interactive={true}
          />
        }
        footer={<MeetingRoomInfoFooter room={location} />}
      />
    );

    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  };

  return (
    <Tile title={tileTitle}>
      {(location || canUserAddRoom) && (
        <div className="h-80 w-full rounded-xl bg-collaborative-blue-50 flex items-center justify-center relative">
          {location && (
            <>
              <MeetingRoomMapView room={location} />
              <ExpandMapButton
                onClick={expandMap}
                className={'absolute top-4 right-4'}
              />
            </>
          )}

          {!location && canUserAddRoom && (
            <Button
              aria-label="Add a room"
              button="tertiary"
              iconRight="plus"
              onClick={handleAddRoomClick}>
              {addRoomLabel}
            </Button>
          )}
        </div>
      )}
      <div className="flex gap-4 w-full">
        <div className="flex justify-center items-center size-12 bg-beige-500* rounded-lg flex-none">
          <Icon
            icon="calendar"
            size={'22px'}
          />
        </div>
        <div className="flex flex-col mr-auto min-w-0">
          <strong>{timeLabel}</strong>
          <p className="truncate">{meeting.title}</p>
          {location && (
            <p>
              {location.displayName}, {location.floorName}
            </p>
          )}
        </div>

        {showTiles && (
          <div className="h-[40px] flex items-center gap-2 flex-none">
            {attendeeTiles.map((t) => (
              <div
                key={t.icon}
                className={`flex rounded-md items-center  px-2 py-1 gap-1 ${
                  t.primary ? 'bg-energizing-yellow-500*' : 'bg-beige-400'
                }`}>
                <Icon
                  className="flex-none w-[20px]"
                  icon={t.icon}
                  size={'20px'}
                />
                <p className="mt-0.5">{t.count}</p>
              </div>
            ))}
          </div>
        )}
        <div>
          <IconButton
            icon="info"
            iconButton="tertiary"
            onClick={handleInfoClick}
            aria-label="Show meeting information"
            className="rounded-lg"
          />
        </div>
      </div>
    </Tile>
  );
};

export const UpcomingMeetingsTile = ({date, time}: {date: Date; time: Date}) => {
  const meetings = useAppSelector((state) => getMeetingsByDate(state, date));
  const meetingsLoadingStatus = useAppSelector((state) => getMeetingsLoadingStatusByDate(state, date));

  if (meetingsLoadingStatus !== 'Loaded') {
    return <LoaderTile />;
  }

  const visibleMeetings = selectMeetingsToShow(meetings, time, {
    allDayUntilHour: 10,
    onGoingIfStartedMaxMinutesAgo: 15,
  });

  return visibleMeetings.map((m, i) => (
    /* TODO: why does this warn for non-unique key? */
    <UpcomingMeetingTile
      key={`TILE_${m.id}`}
      time={time}
      meeting={m}
    />
  ));
};
