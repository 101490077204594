import {useTranslation} from 'react-i18next';
import {NotificationsCardCellProps} from './types';
import {StyledBadge, StyledIconWrapper, StyledNotificationCardCell, StyledWrapper} from './styles';

import {formatDate, isBeforeToday, useTransformNotification} from '@lib/utils';
import {pxToRem} from '@utils';

import {useAppDispatch, useAppSelector, useSidePanel} from '@hooks';
import {deleteNotification, withAsyncThunkErrorHandling, getAppLanguage, trackEvent} from '@lib/store';
import {getBadgeProps, getNotificationIcon} from './helpers';

import {Div, FlexRow, H3, Subtitle} from '@quarks';
import {Icon} from '@atoms';
import {Badge, IconButton} from '@molecules';
import {HighlightedText} from '@organisms';
import {parseISO, startOfToday} from 'date-fns';

export const NotificationsCardCell = ({notification}: NotificationsCardCellProps) => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const appLanguage = useAppSelector(getAppLanguage);

  const {notificationType, title, message, transformedMessageValues, timeAgoText, isRead, icon} =
    useTransformNotification(notification, getNotificationIcon, t, appLanguage);
  const {closeSidePanel} = useSidePanel();
  const formatedDate = formatDate(parseISO(notification.messageValues.ShiftDate), 'yyyy-MM-dd', 'en');

  const handleDeleteNotification = () => {
    dispatch(withAsyncThunkErrorHandling(() => deleteNotification(notification))).then(() => {
      dispatch(
        trackEvent('Notifications_Notification__Delete', {
          type: notificationType,
          date: formatedDate,
        }),
      );
    });
  };

  const isDayInPast = isBeforeToday(parseISO(notification.messageValues.ShiftDate), startOfToday());

  const dayOverviewLink = `${formatedDate}`;

  return (
    <StyledNotificationCardCell key={notification.id}>
      <StyledIconWrapper
        card="beige500"
        shadow={false}>
        <Icon
          color="currentColor"
          icon={icon}
          size={pxToRem(24)}
        />
        <Badge
          border="2px solid white"
          bottom={pxToRem(4)}
          right={pxToRem(4)}
          size="large"
          {...getBadgeProps(notificationType)}
        />
      </StyledIconWrapper>

      <StyledWrapper $isPast={isDayInPast}>
        <FlexRow alignItems="center">
          <H3
            as="h2"
            color="inherit">
            {title}
          </H3>
          {!isRead && (
            <StyledBadge
              aria-label={t('notifications:UnreadNotification')}
              badge="notification"
              role="img"
            />
          )}
        </FlexRow>
        <HighlightedText
          onClick={() => {
            dispatch(
              trackEvent('Notifications_Notification__Click', {
                type: notificationType,
                date: formatedDate,
              }),
            );
            closeSidePanel({silent: true});
          }}
          text={message}
          highlight={transformedMessageValues.ShiftDate}
          highlightTo={!isDayInPast ? dayOverviewLink : undefined}
        />
        <Subtitle>{timeAgoText}</Subtitle>
      </StyledWrapper>

      <Div alignSelf="center">
        <IconButton
          aria-label={t('notifications:DeleteNotification')}
          iconButton="tertiary"
          icon="trash"
          size="small"
          onClick={handleDeleteNotification}
        />
      </Div>
    </StyledNotificationCardCell>
  );
};
