import {Icon} from '@atoms';
import {Button, UserCell} from '@molecules';
import {Div, P, Subtitle} from '@quarks';
import styled from 'styled-components';

export const WorkspaceInformationWrapper = styled(Div)`
  background: ${({theme}) => theme.background.white};
  display: flex;
  height: var(--modal-height);
  flex-direction: column;
  width: inherit;
`;

export const StyledParagraph = styled(P)`
  text-transform: lowercase;
  ::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledUserCell = styled(UserCell)`
  padding: 0;
  :not(:last-child) {
    border-bottom: none;
  }
`;

export const StyledSubtitle = styled(Subtitle)<{$alertState: boolean}>`
  color: ${({$alertState, theme}) => ($alertState ? theme.text.color.error : theme.text.color.subtitle)};
`;

export const StyledButton = styled(Button)`
  align-self: flex-start;
`;

export const InactiveStyledIcon = styled(Icon).attrs(({theme, ...attrs}) => ({
  ...attrs,
  color: theme.icon.inactive,
}))``;
