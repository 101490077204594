import {PartOfDay, useAppSelector, useBreakPoint, useFeatureToggle, useHomeTime} from '@hooks';
import {
  getEnabledFeaturesLoadingStatus,
  getIsLoadingParkingReservations,
  getIsLoadingSettings,
  getIsLoadingWorkspaceReservations,
  getIsMeetingsCalendarEnabled,
  getIsUserCalendarConsentInvalid,
  getIsWorkdayLoading,
  getMeetingPreferencesLoadingStatus,
  getUser,
  getWorkdayByDate,
} from '@lib/store';
import {PageHeader} from '@molecules';
import {FlexCol, FlexRow} from '@quarks';
import {format, getHours, setHours, setMinutes, startOfDay} from 'date-fns';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {Sidebar} from './Components/Sidebar/Sidebar';
import {
  AvailableRoomsTile,
  AvailableWorkspacesTile,
  DiscoverTheOfficeTile,
  MeetingConsentTile,
  NextWorkdaysTile,
  UpcomingMeetingsTile,
  WorkdayTile,
} from '@organisms';
import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Loader} from '@atoms';
import {ConnectionsTile} from 'src/components/organisms/home/Connections';

// useXReady -> we don't need all data, but we do need to know enough for the component to be able
//              to render at a fixed height
// Note: these could be selectors but I feel they are too tied to UI to really be reusable
const useWorkdayTileReady = () => {
  // Different sizes based on which 3 sections are available
  // If we implement a design that has the same height for all combinations, we can simply return true here.
  // For now, I think it's better to only add sections if they are relevant (e.g. don't add a map if there's
  // no workday)
  const workdayReady = !useAppSelector(getIsWorkdayLoading);
  const workspaceReady = !useAppSelector(getIsLoadingWorkspaceReservations);
  const parkingReady = !useAppSelector(getIsLoadingParkingReservations);

  return workdayReady && workspaceReady && parkingReady;
};

const useWorkdayTileRelevant = () => {
  return true;
};

const useUpcomingMeetingsTileReady = () => {
  const meetingSettingsReady = !useAppSelector(getIsLoadingSettings);
  const consentRequestReady = useAppSelector(getMeetingPreferencesLoadingStatus) === 'Loaded';

  return meetingSettingsReady && consentRequestReady;
};

const useUpcomingMeetingsTileRelevant = () => {
  const {HybridMeetingsCalendarView: hybridMeetingsFeatureEnabled} = useFeatureToggle();
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const isMeetingCalendarValid = !useAppSelector(getIsUserCalendarConsentInvalid);

  return hybridMeetingsFeatureEnabled && isMeetingCalendarEnabled && isMeetingCalendarValid;
};

const useMeetingConsentTileReady = () => {
  return useAppSelector(getMeetingPreferencesLoadingStatus) === 'Loaded';
};

const useMeetingConsentTileRelevant = () => {
  const {HybridMeetingsCalendarView: hybridMeetingsFeatureEnabled} = useFeatureToggle();
  const isMeetingCalendarEnabled = useAppSelector(getIsMeetingsCalendarEnabled);
  const isMeetingCalendarValid = !useAppSelector(getIsUserCalendarConsentInvalid);

  return hybridMeetingsFeatureEnabled && (!isMeetingCalendarEnabled || !isMeetingCalendarValid);
};

const useDiscoverTheOfficeTileReady = () => {
  // We only show it if we know there is an in office workday
  const workdayReady = !useAppSelector(getIsWorkdayLoading);

  return workdayReady;
};

const useDiscoverTheOfficeTileRelevant = (dateKey: string) => {
  const {ExploreOnHereAndNow: exploreFeatureIsEnabled} = useFeatureToggle();
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey));
  const hasInOfficeWorkday = workday?.status === 'OfficeDay';
  return exploreFeatureIsEnabled && hasInOfficeWorkday;
};

const useAvailableRoomsRelevant = () => {
  const {HybridMeetingsCalendarView: hybridMeetingsFeatureEnabled, Occupancy: occupancyFeatureEnabeld} =
    useFeatureToggle();
  return hybridMeetingsFeatureEnabled && occupancyFeatureEnabeld;
};

const useAvailableRoomsReady = () => {
  // We only show it if we know there is an in office workday
  const workdayReady = !useAppSelector(getIsWorkdayLoading);

  return workdayReady;
};

const useAvailableWorkspacesRelevant = (dateKey: string) => {
  const {Occupancy: occupancyFeatureEnabeld} = useFeatureToggle();
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey));
  const hasInOfficeWorkday = workday?.status === 'OfficeDay';
  return occupancyFeatureEnabeld && hasInOfficeWorkday;
};

const useAvailableWorkspacesReady = () => {
  // We only show it if we know there is an in office workday
  const workdayReady = !useAppSelector(getIsWorkdayLoading);

  return workdayReady;
};

const useNextWorkdaysRelevant = () => true;

const useNextWorkdaysReady = () => true;

const useHomePageSections = (dateKey: string, partOfDay: PartOfDay) => {
  const overview = [
    {
      componentKey: 'WorkdayTile',
      ready: useWorkdayTileReady(),
      relevant: useWorkdayTileRelevant(),
    },

    {
      componentKey: 'UpcomingMeetingsTile',
      ready: useUpcomingMeetingsTileReady(),
      relevant: useUpcomingMeetingsTileRelevant(),
    },

    {
      componentKey: 'MeetingConsentTile',
      ready: useMeetingConsentTileReady(),
      relevant: useMeetingConsentTileRelevant(),
    },

    {
      componentKey: 'ConnectionsTile',
      ready: true, // Connection reservations can be slow to load, but tile has constant height design
      relevant: useBreakPoint() === 'small', // Connections is a default feature everyone has
    },

    {
      componentKey: 'AvailableRoomsTile',
      ready: useAvailableRoomsReady(),
      relevant: useAvailableRoomsRelevant() && partOfDay === 'WorkingHours',
    },

    {
      componentKey: 'AvailableWorkspacesTile',
      ready: useAvailableWorkspacesReady(),
      relevant: useAvailableWorkspacesRelevant(dateKey) && partOfDay === 'WorkingHours',
    },

    {
      componentKey: 'DiscoverTheOfficeTile',
      ready: useDiscoverTheOfficeTileReady(),
      relevant: useDiscoverTheOfficeTileRelevant(dateKey),
    },
    {
      componentKey: 'NextOfficeDaysTile',
      ready: useNextWorkdaysReady(),
      relevant: useNextWorkdaysRelevant(),
    },
  ] as const;

  // Return up to the first that is not ready
  const firstLoadingIndex = overview.findIndex((t) => !t.ready);

  const toRender = firstLoadingIndex === -1 ? overview : overview.slice(0, firstLoadingIndex);

  return toRender.filter((r) => r.relevant);
};

export const Home = () => {
  const {AppHomePage_DebugTime} = useFeatureToggle();

  // Use automatically updating time. Note: using this will cause repeated renders of the whole component every tick
  const {time, timeDisplay, todayDate, tomorrowDate, setDebugTime, usesDebugTime, partOfDay} = useHomeTime(5000);

  // The reason this is here is because it whispers of the fleeting nature of life,
  // a bittersweet truth that we can never escape.
  // And... we shouldn't forget re-rendering every x-seconds might get expensive
  console.log('TICK');

  const flagsLoading = useAppSelector(getEnabledFeaturesLoadingStatus) !== 'Loaded';
  const referenceDate = partOfDay === 'Evening' ? tomorrowDate : todayDate;
  const referenceDateKey = format(referenceDate, 'yyyy-MM-dd');
  const sections = useHomePageSections(referenceDateKey, partOfDay);

  // No point to continue if we're still waiting for feature flags
  if (flagsLoading) return <div>LOADING...</div>;
  // TODO: some memoization might help to prevent re-rendering of tiles on every tick
  //       at the same time, we might not need to worry about it if we make sure we don't
  //       do slow or weird things in these sections
  const newTiles = sections.map((s) => {
    // If these tiles had the same props, this would be a bit cleaner
    switch (s.componentKey) {
      case 'WorkdayTile':
        return (
          <WorkdayTile
            date={referenceDate}
            key={s.componentKey}
          />
        );
      case 'UpcomingMeetingsTile':
        return (
          <UpcomingMeetingsTile
            date={referenceDate}
            time={time}
            key={s.componentKey}
          />
        );
      case 'MeetingConsentTile':
        return <MeetingConsentTile key={s.componentKey} />;
      case 'AvailableRoomsTile':
        return (
          <AvailableRoomsTile
            time={time}
            key={s.componentKey}
          />
        );
      case 'AvailableWorkspacesTile':
        return (
          <AvailableWorkspacesTile
            time={time}
            key={s.componentKey}
          />
        );
      case 'DiscoverTheOfficeTile':
        return (
          <DiscoverTheOfficeTile
            key={s.componentKey}
            dateKey={referenceDateKey}
          />
        );
      case 'NextOfficeDaysTile':
        return (
          <NextWorkdaysTile
            key={s.componentKey}
            date={referenceDate}
          />
        );
      case 'ConnectionsTile':
        return (
          <ConnectionsTile
            key={s.componentKey}
            date={referenceDate}
          />
        );
    }
  });

  return (
    <HomeLayoutWrapper>
      <HomeLayout>
        {/* Header */}
        <FixedHeader>
          <div>
            <Greeting time={time} />

            {AppHomePage_DebugTime ? (
              <fieldset>
                <legend>Page time:</legend>
                <FlexRow
                  alignItems="center"
                  gap={8}>
                  <input
                    readOnly={!usesDebugTime}
                    onChange={(e) => {
                      const [h, m] = e.target.value.split(':').map(Number);
                      if (typeof h !== 'number' || typeof m !== 'number') return;

                      const today = startOfDay(time);

                      const atHour = setHours(today, h);
                      const atMinute = setMinutes(atHour, m);

                      setDebugTime(atMinute);
                    }}
                    type="time"
                    value={timeDisplay}
                  />
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={usesDebugTime}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDebugTime(time);
                        } else {
                          setDebugTime(null);
                        }
                      }}
                    />
                    Override
                  </label>
                </FlexRow>
              </fieldset>
            ) : null}
          </div>
        </FixedHeader>

        <HomeBody
          as="main"
          justifyContent="flex-start"
          gap={16}>
          {newTiles}
          {newTiles.length === 0 && <Loader />}
        </HomeBody>
        <HomeSidebar
          gap={32}
          justifyContent="flex-start">
          <Sidebar date={referenceDate} />
        </HomeSidebar>
        {/* Body */}
      </HomeLayout>
    </HomeLayoutWrapper>
  );
};

/*
 First home page component that uses time:
*/
const getGreeting = (time: Date, t: TFunction): string => {
  const hour = getHours(time);

  if (hour < 4 || hour >= 22) {
    return t('screen:GreetingNight');
  } else if (hour < 12) {
    return t('screen:GreetingMorning');
  } else if (hour < 17) {
    return t('screen:GreetingAfternoon');
  } else if (hour < 22) {
    return t('screen:GreetingEvening');
  }

  throw new RangeError(`Bug in getGreeting, no valid response for hour ${hour}`);
};

const Greeting = ({time}: {time: Date}) => {
  const {t} = useTranslation();
  const user = useAppSelector(getUser);

  return <PageHeader title={`${getGreeting(time, t)} ${user.firstName}`} />;
};

const HomeLayoutWrapper = styled.div`
  background: #fdfaf7;
  grid-area: canvas;

  ${BREAKPOINTS.small`
    grid-row: header / canvas;
  `}
`;

const HomeLayout = styled('article')`
  --verticalOffset: 60px; // TODO: get this from actual layout...
  --headerHeight: 120px;
  --headerFadeHeight: 50px;

  --mainWidth: 800px;
  --sideWidth: 320px;
  --gutterWidth: 2rem;
  --homeLayoutWidth: calc(var(--mainWidth) + var(--sideWidth) + var(--gutterWidth));

  height: calc(100vh - var(--verticalOffset));
  max-width: var(--homeLayoutWidth);
  padding: 0 var(--gutterWidth);
  margin: 0 auto;

  position: relative;
  display: grid;

  grid-template-rows: var(--headerHeight) 1fr;
  grid-template-columns: 2.5fr 1fr;
  grid-template-areas:
    'header-main header-sidebar'
    'main sidebar';
  column-gap: var(--gutterWidth);

  justify-content: center;

  ${BREAKPOINTS.large`
    grid-row: header / canvas;

    grid-template-rows: var(--verticalOffset) auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header-main'
      'main'
      'sidebar';

    grid-column-gap: 0;
    grid-row-gap: 0 1rem;
    max-width: 1000px;
    height: auto;
  `}

  ${BREAKPOINTS.small`
    padding: 0;
    max-width: unset;
  `}
`;

const FixedHeader = styled('header')`
  position: absolute;
  z-index: 1;
  grid-column: header-main / header-sidebar;
  padding-top: 1rem;

  width: 100%;
  height: var(--headerHeight);

  background: linear-gradient(
    180deg,
    #fdfaf7 calc(var(--headerHeight) - var(--headerFadeHeight)),
    rgba(255, 255, 255, 0) 100%
  );

  ${BREAKPOINTS.large`
    position: relative;
    background: transparent;
    height: auto;
  `}

  ${BREAKPOINTS.small`
    padding: 0 1rem;
    display: flex;
    align-items: center;
    height: var(--verticalOffset);

    background: white;
    border-bottom: 1px solid #e8e8e8;
  `}
`;

const HomeBody = styled(FlexCol)`
  grid-row: header-main / main;
  padding-top: var(--headerHeight);

  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: main;
    padding: 0;
    padding-bottom: 4rem;
  `}

  ${BREAKPOINTS.small`
    grid-row: unset;
    grid-area: main;
    padding-bottom: 1rem;
  `}
`;

const HomeSidebar = styled(FlexCol)`
  grid-row: header-sidebar / sidebar;
  padding-top: var(--headerHeight);

  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: sidebar;
    padding: 0;
  `}
`;
