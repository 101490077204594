import {
  useAcceptDeclineWorkdayInvitation,
  useAppDispatch,
  useAppSelector,
  useModal,
  useConfirmationDialog,
  useUserProfilePics,
} from '@hooks';
import {
  getCanChangeWorkspaceReservation,
  getCanDeleteWorkspaceReservation,
  getIsDeletingRegistration,
  getIsUserOwnerOfWorkspaceReservationByDate,
  getAreaById,
  GetBuildingLocationsProps,
  getNodeInformationByNodeId,
  getWorkspaceReservationSpaceNodeId,
  getAllBuildingLocations,
  getUser,
  ReservationMember,
  MeetingResponseStatus,
  getAppLanguage,
  getFloorHasMap,
  deleteWorkspaceReservation,
  withThunkErrorHandling,
  trackEvent,
} from '@lib/store';

import {Date as DateComponent, IconNames, TwoColumnCell} from '@atoms';
import {UserCell, CapacityIndicator, RSVP, ModalCellItemIcon, ModalCell} from '@molecules';
import {
  BookingDetailsConnections,
  BookWorkspaceCard,
  MapPreviewWithSuggestionsCard,
  MapSelectorCard,
  ModalPageBottom,
} from '@organisms';
import {Div, FlexCol, FlexRow, H4, P, Span, Subtitle} from '@quarks';
import toast from 'react-hot-toast';
import {parseISO} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {BookingDetailsCardProps} from './types';
import {RESERVATION_STATUS, formatDate} from '@lib/utils';
import {pxToRem, sortMembersByResponse} from '@utils';
import isNumber from 'lodash/isNumber';
import {StyledModalPage} from './styles';

export const BookingDetailsCard = ({date, workspaceReservation, mapView}: BookingDetailsCardProps) => {
  const {t} = useTranslation();
  const {addPages, closeModal, modalPages, removePages} = useModal();
  const {confirmationDialog} = useConfirmationDialog();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(getUser);
  const appLanguage = useAppSelector(getAppLanguage);
  const isDeletingRegistration = useAppSelector(getIsDeletingRegistration);
  const canDeleteWorkspaceReservation = useAppSelector((state) => getCanDeleteWorkspaceReservation(state, date));
  const canChangeWorkspaceReservation = useAppSelector((state) => getCanChangeWorkspaceReservation(state, date));
  const area = useAppSelector((state) => getAreaById(state, workspaceReservation.areaId || ''));
  const isUserOwnerOfReservation = useAppSelector((state) =>
    getIsUserOwnerOfWorkspaceReservationByDate(state, parseISO(date)),
  );
  const selectedSpaceNodeId = getWorkspaceReservationSpaceNodeId(workspaceReservation);
  const {capacity, occupation} = useAppSelector((state) =>
    getNodeInformationByNodeId(state, {date: parseISO(date), nodeId: selectedSpaceNodeId}),
  );

  const {
    acceptInvitation,
    declineInvitation,
    loading: updatingInvitationStatus,
  } = useAcceptDeclineWorkdayInvitation({
    date,
    workspaceReservationId: workspaceReservation.id || '',
    onSuccess: () => {
      closeModal();
    },
  });

  const {
    areaName,
    floorName,
    buildingName,
    areaId = '',
    floorId = '',
    buildingId = '',
  } = workspaceReservation.currentMember?.location ?? {};

  const locationsByBuildingIdProps: GetBuildingLocationsProps = {
    buildingId: buildingId,
    date: date,
    userNodeId: selectedSpaceNodeId,
    onlyWithConnections: true,
  };

  const userId = useAppSelector(getUser).id;

  const floorHasMap = useAppSelector(getFloorHasMap(floorId));

  const sortedMembersByResponse = sortMembersByResponse([...(workspaceReservation?.members || [])]);

  const title = areaName || floorName || buildingName;
  const subtitle = areaId ? `${buildingName}, ${floorName}` : buildingName;

  const isInvitation = workspaceReservation.status === RESERVATION_STATUS.InvitationPending;

  const isGroupBooking = !!workspaceReservation.groupReservationId;
  const bookedByUser = workspaceReservation.createdBy;

  const connectionsWithWorkspaceReservationsInBuilding = isGroupBooking
    ? t('connection:OtherConnectionsWorkingInBuilding', {
        building: buildingName,
      })
    : t('connection:ConnectionsWorkingInBuilding', {
        building: buildingName,
      });

  const locationsWithConnectionsByBuildingId = useAppSelector((state) =>
    getAllBuildingLocations(state, locationsByBuildingIdProps),
  );

  const areWorkspaceMembersDisplayed = isGroupBooking;

  const getSubtitle = (reservationMember: ReservationMember) => {
    if (reservationMember.status === RESERVATION_STATUS.Confirmed) {
      return reservationMember.location.deskName;
    }
    if (
      userId === reservationMember.userId ||
      (workspaceReservation.owner?.userId === userId &&
        reservationMember.status === RESERVATION_STATUS.InvitationPending)
    ) {
      const {remainingDaysToReply} = reservationMember;
      if (!isNumber(remainingDaysToReply) || remainingDaysToReply < 0) return;

      return remainingDaysToReply > 0
        ? t('workplace:DayToRespond', {count: remainingDaysToReply})
        : t('workplace:FinalDayToRespond');
    }
  };

  const getInviteeAvailability = (reservationMember: ReservationMember): MeetingResponseStatus => {
    switch (reservationMember.status) {
      case RESERVATION_STATUS.Confirmed:
        return 'Accepted';
      case RESERVATION_STATUS.InvitationPending:
        return 'Pending';
      default:
        return 'Declined';
    }
  };

  const deleteHandler = async () => {
    if (isGroupBooking && isUserOwnerOfReservation) {
      const result = await confirmationDialog(
        t('workplace:DeleteGroupBookingDialogTitle'),
        t('workplace:DeleteGroupBookingDialogText'),
        'warning',
      );

      if (!result) return;
    }

    deleteBooking();
  };

  const deleteBooking = async () => {
    if (!workspaceReservation) return;

    const {success} = await dispatch(withThunkErrorHandling(() => deleteWorkspaceReservation(workspaceReservation)));

    if (success) {
      toast.success(
        isGroupBooking && isUserOwnerOfReservation
          ? t('workplace:GroupBookingCancelledMessage')
          : t('workplace:WorkplaceRemovedMessage'),
      );
      closeModal();

      dispatch(
        trackEvent('CalendarOverview_BookingDetails__Delete', {
          date,
          isGroupBooking,
        }),
      );
    }
  };

  const confirmedOrPendingMembers = workspaceReservation.members.filter(
    ({status}) => status === RESERVATION_STATUS.Confirmed || status === RESERVATION_STATUS.InvitationPending,
  );

  const memberProfileImages = useUserProfilePics(
    confirmedOrPendingMembers.map((_member) => ({
      userId: _member.userId,
      imageHash: _member.user.imageHash ?? '',
    })),
  );

  const icon: IconNames = area?.hasBookableDesks ? 'desk' : areaId ? 'area' : 'floor';

  const toasterData = confirmedOrPendingMembers.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.nodeId]: {
        user: curr.user,
        displayName: (curr.userId === currentUser.id ? t('You') : curr.user.displayName) ?? '',
        deskName: curr.location.deskName,
      },
    };
  }, {});

  const mapViewUsers = confirmedOrPendingMembers.map(({user: {id, email, initials, imageHash}}) => ({
    id: id,
    email: email,
    initials: initials,
    imageHash: imageHash ?? '',
    imageUrl: memberProfileImages[id],
  }));

  const deskNames = confirmedOrPendingMembers.map(({location}) => location.deskName).filter((id): id is string => !!id);
  const currentDeskIds = confirmedOrPendingMembers
    .map(({location}) => location.deskId)
    .filter((id): id is string => !!id);

  return (
    <StyledModalPage
      title={
        isInvitation
          ? t('screen:WorkspaceInvitationDetails')
          : isGroupBooking
          ? t('workplace:GroupBooking')
          : t('screen:DeskDetail')
      }
      onBack={modalPages.length > 1 ? () => removePages(1) : undefined}
      bottom={
        <ModalPageBottom
          buttonLabel={
            isInvitation
              ? t('translation:Accept')
              : isUserOwnerOfReservation
              ? t('workplace:DeleteBooking')
              : t('Decline')
          }
          onClick={isInvitation ? acceptInvitation : deleteHandler}
          buttonLoading={isDeletingRegistration || updatingInvitationStatus}
          onCancel={
            isInvitation
              ? declineInvitation
              : canChangeWorkspaceReservation && isUserOwnerOfReservation
              ? () => {
                  addPages([<BookWorkspaceCard date={date} />]);
                  dispatch(
                    trackEvent('CalendarOverview_BookingDetails__StartEdit', {
                      date,
                      isGroupBooking,
                    }),
                  );
                }
              : undefined
          }
          destructive={!isInvitation}
          buttonType={isInvitation ? 'primary' : 'secondary destructive'}
          cancelButtonType={isInvitation ? 'secondary' : 'tertiary'}
          cancelButtonLabel={isInvitation ? t('translation:Decline') : t('Edit')}
          cancelButtonDisabled={!isInvitation && !isUserOwnerOfReservation}
          buttonDisabled={!isInvitation && !canDeleteWorkspaceReservation}
        />
      }
      onClose={() => {
        closeModal();
        dispatch(
          trackEvent('CalendarOverview_BookingDetails__Close', {
            date,
            isGroupBooking,
          }),
        );
      }}>
      {mapView && floorHasMap ? (
        <FlexCol
          gap={16}
          height={'100%'}>
          <MapSelectorCard
            date={date}
            buildingId={buildingId}
            floorId={floorId}
            areaId={areaId}
            // Not passing desks ensures the card goes in to area mode
            deskIds={currentDeskIds.length ? currentDeskIds : undefined}
            users={mapViewUsers}
            toasterData={toasterData}
          />
          <ModalCell>
            <TwoColumnCell
              padding="small"
              alignItems="flex-start"
              separator={false}
              left={<ModalCellItemIcon icon={icon} />}
              right={
                <FlexCol minHeight={pxToRem(48)}>
                  <Span fontWeight="bold">{title}</Span>
                  <Subtitle>{subtitle}</Subtitle>
                </FlexCol>
              }
            />
          </ModalCell>
        </FlexCol>
      ) : (
        <ModalCell>
          <FlexCol gap={16}>
            {floorHasMap && (
              <MapPreviewWithSuggestionsCard
                buildingId={buildingId}
                floorId={floorId}
                areaId={areaId}
                deskIds={currentDeskIds}
                groupSize={workspaceReservation.members.length}
                deskNames={deskNames}
                onClick={() => {
                  addPages([
                    <BookingDetailsCard
                      date={date}
                      workspaceReservation={workspaceReservation}
                      mapView
                    />,
                  ]);
                }}
              />
            )}
            <FlexRow alignItems="flex-start">
              <FlexCol gap={4}>
                <H4 as="h2">{title}</H4>
                <P>{subtitle}</P>
                <P>
                  <DateComponent
                    date={date}
                    format="EEEE dd MMMM"
                  />
                </P>
              </FlexCol>
              <CapacityIndicator
                capacity={capacity}
                occupation={occupation}
              />
            </FlexRow>
            {areWorkspaceMembersDisplayed && (
              <>
                <hr />
                <Div>
                  <H4 as="h2">{t('Invitees')}</H4>
                  {sortedMembersByResponse.map((member) => (
                    <UserCell
                      separator={false}
                      padding="small"
                      key={member.userId}
                      user={
                        member.userId === currentUser.id
                          ? {...member.user, displayName: t('translation:You')}
                          : member.user
                      }
                      right={<RSVP rsvp={member.isOwner ? 'Organizer' : getInviteeAvailability(member)} />}
                      subtitle={getSubtitle(member)}
                    />
                  ))}
                </Div>
              </>
            )}
            {bookedByUser && !isInvitation && !isGroupBooking && (
              <>
                <hr />
                <FlexCol gap={8}>
                  <H4 as="h2">{t('workplace:BookedForYou')}</H4>
                  <Subtitle>
                    {t('workplace:BookedByUserOnDate', {
                      user: bookedByUser,
                      date:
                        workspaceReservation && !!workspaceReservation.createdOrLastModifiedDate
                          ? formatDate(
                              parseISO(workspaceReservation.createdOrLastModifiedDate),
                              'dd/MM/yyyy',
                              appLanguage,
                            )
                          : '',
                    })}
                  </Subtitle>
                </FlexCol>
              </>
            )}
            {locationsWithConnectionsByBuildingId.length > 0 && (
              <FlexCol gap={16}>
                <hr />
                <H4 as="h2">{connectionsWithWorkspaceReservationsInBuilding}</H4>
                {locationsWithConnectionsByBuildingId.map((location, index) => (
                  <BookingDetailsConnections
                    key={`location-${index}`}
                    date={date}
                    location={location}
                  />
                ))}
              </FlexCol>
            )}
          </FlexCol>
        </ModalCell>
      )}
    </StyledModalPage>
  );
};
