import {useTranslation} from 'react-i18next';
import {CalendarEventCellProps} from './types';
import {differenceInMinutes, format, isPast, isWithinInterval, parseISO} from 'date-fns';
import {useCurrentTime} from '@lib/utils/src/hooks/useCurrentTime';

import {
  getMeetingRoomsByMeetingId,
  getAttendeesCountOnLocationByMeetingId,
  getLoggedInUserResponseStatusByMeetingId,
  getWorkdayByDate,
  getCanUserAddRoomToExistingMeeting,
  withAsyncThunkErrorHandling,
  updateMeeting,
  MeetingRoom,
  getDefaultBuildingId,
} from '@lib/store';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';

import {StyledButtonCard, StyledIconButton, StyledLocationWrapper, StyledTime, StyledTitle} from './styles';
import {Div, FlexRow, P, Subtitle} from '@quarks';
import {Icon, Label} from '@atoms';
import {CreateEventManualRoomSelectionCard, MeetingDetailsCard} from '@organisms';
import toast from 'react-hot-toast';
import {useEffect} from 'react';
import {useFilters} from '@lib/utils';

const UPCOMING_MINUTES_THRESHOLD = 10;

export const CalendarEventCell = ({meeting, date}: CalendarEventCellProps) => {
  const {isCanceled, startDateTime, endDateTime, id, isAllDay, title} = meeting;
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const workday = useAppSelector((state) => getWorkdayByDate(state, date));
  const meetingRooms = useAppSelector((state) => getMeetingRoomsByMeetingId(state, id));
  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const meetingResponseStatus = useAppSelector((state) => getLoggedInUserResponseStatusByMeetingId(state, id));
  const attendeeCountByLocationType = useAppSelector((state) => getAttendeesCountOnLocationByMeetingId(state, id));

  const canUserAddRoom =
    useAppSelector((state) => getCanUserAddRoomToExistingMeeting(state, {meetingId: id ?? ''})) &&
    meetingRooms.length === 0;

  const {openModal, addPages, closeModal} = useModal();

  const workdayBuildingId = workday?.nodeId;
  const isAccepted = meetingResponseStatus === 'Accepted' || meetingResponseStatus === 'Organizer';

  const start = parseISO(startDateTime);
  const end = parseISO(endDateTime);
  const {now, updateNow, cancelUpdateNow} = useCurrentTime();

  const startTime = format(start, 'HH:mm');
  const endTime = format(end, 'HH:mm');

  const isInPast = isPast(end);
  const isMeetingOngoing = isWithinInterval(new Date(), {start: parseISO(startDateTime), end: parseISO(endDateTime)});

  const differenceInMinutesFromNow = differenceInMinutes(parseISO(startDateTime), parseISO(now));
  const isMeetingUpcoming = differenceInMinutesFromNow <= UPCOMING_MINUTES_THRESHOLD && differenceInMinutesFromNow > 0;
  const upcomingInMinutes = isMeetingUpcoming && !isCanceled ? differenceInMinutesFromNow : undefined;

  useEffect(() => {
    if (!isCanceled && !isInPast) {
      updateNow();
    }
    return () => {
      cancelUpdateNow();
    };
  }, [isCanceled, updateNow, isInPast, cancelUpdateNow]);

  // Check if any of the meetingRooms exists in the building we have a workday.
  const meetingRoomsInWorkdayBuilding = meetingRooms.filter((room) => room.buildingId === workdayBuildingId);

  // If we have meetingRooms in the building we booked our workday we pick from that array
  // If not we'll pick from the meetingRooms array that was provided in the invitation
  const filteredMeetingRooms = !meetingRoomsInWorkdayBuilding.length ? meetingRooms : meetingRoomsInWorkdayBuilding;

  // More than one meetingRoom: show count of meetingRooms
  // One meetingRoom: show the name
  // No meetingRooms: show nothing
  const locationLabel =
    filteredMeetingRooms.length > 1
      ? t('meeting:MeetingRoomCount', {count: meetingRooms.length})
      : filteredMeetingRooms.length === 1
      ? filteredMeetingRooms[0].displayName
      : '';

  const handleClick = () => {
    addPages([
      <MeetingDetailsCard
        date={date}
        meeting={meeting}
      />,
    ]);
    openModal();
  };

  const handleRoomSelection = async (value: MeetingRoom) => {
    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        updateMeeting({
          origin: 'AddRoomToExistingMeeting',
          meetingId: id,
          systemTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          rooms: [...meetingRooms.map((room) => room.email), value.email],
        }),
      ),
    );
    if (success) {
      closeModal();
      toast.success(t('meeting:AddMeetingRoomToMeetingSuccessMessage', {roomName: value.displayName}));
    }
  };

  const {setConfig} = useFilters('CalendarOverview_AdHocRoom');
  useEffect(() => {
    return () => {
      setConfig({});
    };
  }, [setConfig]);

  const openRoomsSelection = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    addPages([
      <CreateEventManualRoomSelectionCard
        defaultBuildingId={defaultBuildingId}
        origin="CalendarOverview_AdHocRoom"
        endDateTime={endDateTime}
        onClose={closeModal}
        onSelect={handleRoomSelection}
        selectedRooms={meetingRooms.map((room) => room.email)}
        startDateTime={startDateTime}
      />,
    ]);
    openModal();
  };

  return (
    <Div
      position="relative"
      display="flex"
      sm={{
        width: 'calc(100vw - 64px)',
      }}>
      <StyledButtonCard
        data-testid="organisms-calendar-CalendarEventCell-button-card"
        $isOfficeDay={!!workdayBuildingId}
        $isAccepted={isAccepted}
        $isCanceled={isCanceled}
        $isOngoing={!isAllDay && (isMeetingOngoing || isMeetingUpcoming)}
        onClick={handleClick}>
        <FlexRow>
          <Div>
            <StyledTime $isCanceled={isCanceled}>
              {isAllDay ? t('meeting:AllDay') : `${startTime} - ${endTime}`}
            </StyledTime>
            <StyledTitle>{title}</StyledTitle>
          </Div>

          {upcomingInMinutes && <Subtitle>{t('StartsIn', {count: upcomingInMinutes})}</Subtitle>}
        </FlexRow>
        {!isCanceled && (
          <FlexRow gap={16}>
            <FlexRow
              gap={16}
              flex="none">
              {attendeeCountByLocationType.map(({attendeesNumber, locationType}, index) => (
                <StyledLocationWrapper
                  $isAccepted={isAccepted}
                  key={index}>
                  <Icon
                    color="currentColor"
                    icon={
                      locationType === 'Local' ? 'office' : locationType === 'Remote' ? 'remote' : 'locationUnknown'
                    }
                    aria-label={
                      locationType === 'Local'
                        ? 'Number of local participants'
                        : locationType === 'Remote'
                        ? 'Number of remote participants'
                        : 'Number of participants with an unknown location'
                    }
                    size="20px"
                    weight="thin"
                  />
                  <P
                    fontSize="sm"
                    fontWeight="regular">
                    {attendeesNumber}
                  </P>
                </StyledLocationWrapper>
              ))}
            </FlexRow>
            {meetingRooms.length > 0 && (
              <Label
                fontSize="sm"
                data-testid={`organisms-calendar-CalendarEventCell_rooms-${title}`}
                label="connectingGreen">
                {locationLabel}
              </Label>
            )}
          </FlexRow>
        )}
      </StyledButtonCard>
      {canUserAddRoom && (
        <StyledIconButton
          iconButton="secondary"
          aria-label={t('screen:NewEventRoomSelection')}
          disabled={isCanceled}
          onClick={openRoomsSelection}
          icon="addLocation"
          size="small"
          data-testid={`organisms-calendar-CalendarEventCell_add-room-${title}`}
        />
      )}
    </Div>
  );
};
