import {useAppSelector} from '@hooks';
import {ConnectionUserDto, WorkdayDto, WorkspaceReservationDto} from '@lib/infrastructure';
import {
  getBuildingById,
  getConnectionWhereaboutsForDay,
  getDefaultBuildingId,
  getTotalConnections,
  getWorkdayByDate,
} from '@lib/store';

type RelevantConnections =
  | {status: 'Loading'; connections: null; buildingName: null}
  | {status: 'UserHasNoConnections'; connections: null; buildingName: string}
  | {status: 'NoOneThere'; connections: []; buildingName: string}
  | {
      status: 'Done';
      connections: {
        user: ConnectionUserDto;
        workday: WorkdayDto;
        workspace: WorkspaceReservationDto | null;
      }[];
      buildingName: string;
    };

export const useRelevantConnections = (dateKey: string): RelevantConnections => {
  const defaultBuildingId = useAppSelector(getDefaultBuildingId);
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey)) ?? null;
  const buildingId = workday?.nodeId ?? defaultBuildingId;

  const connectionsToday = useAppSelector((state) => getConnectionWhereaboutsForDay(state, dateKey));

  const hasConnections = useAppSelector(getTotalConnections) > 0;

  const workdayLoading = useAppSelector((state) => state.data.workday.isLoading);
  const connectionsLoading = connectionsToday === null;

  const buildingName =
    useAppSelector((state) => getBuildingById(state, buildingId))?.name ?? workday?.nodeName ?? 'Unknown office';

  if (workdayLoading || connectionsLoading) {
    return {
      status: 'Loading',
      connections: null,
      buildingName: null,
    };
  }

  if (!hasConnections) {
    return {
      status: 'UserHasNoConnections',
      connections: null,
      buildingName,
    };
  }

  const connectionsInYourOffice = connectionsToday.inSomeOffice.filter((r) => r.workday.nodeId === buildingId);

  if (connectionsInYourOffice.length === 0) {
    return {
      status: 'NoOneThere',
      connections: [],
      buildingName,
    };
  }

  return {
    status: 'Done',
    connections: connectionsInYourOffice,
    buildingName,
  };
};
