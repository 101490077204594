import {Icon} from '@atoms';
import {useAppDispatch, useAppSelector, useBreakPoint, useRoomImage} from '@hooks';
import {
  Resource,
  getBuildingResources,
  getDefaultBuildingId,
  getWorkdayByDate,
  loadBuildingResources,
} from '@lib/store';
import {LoaderTile, Tile} from '@organisms';
import {useEffect} from 'react';

import {cn} from '@utils';
import {CategoryConfig, useCategoryTiles} from './useCategoryTiles';

// This tile checks the loading state and resolves resources for the correct building
export const DiscoverTheOfficeTile = ({dateKey}: {dateKey: string}) => {
  const dispatch = useAppDispatch();

  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey));
  const defaultOfficeId = useAppSelector(getDefaultBuildingId);
  const buildingId = workday?.nodeId ?? defaultOfficeId;
  const resourceData = useAppSelector((state) => getBuildingResources(state, buildingId));

  const loadedResources = resourceData?.resources ?? [];
  const loading = !resourceData || resourceData.status === 'loading';

  // Note: cannot move out of effect due to faulty setState
  useEffect(
    function loadResourceData() {
      if (!resourceData && buildingId) {
        dispatch(loadBuildingResources(buildingId));
      }
    },
    [buildingId, resourceData, dispatch],
  );

  if (loading) return <LoaderTile rows={Array(2).fill([180, 180, 180])} />;

  return (
    <Tile title={'Explore the office'}>
      <CategoryTable
        resources={loadedResources}
        buildingId={buildingId}
        columns={3}
      />
    </Tile>
  );
};

// This component transforms a bunch of resources for a building to a relevant set of clickable tiles
const CategoryTable = ({
  buildingId,
  resources,
  columns,
}: {
  buildingId: string;
  resources: Resource[];
  columns: 1 | 2 | 3 | 4;
}) => {
  const tiles = useCategoryTiles(buildingId, resources);

  return (
    <div className={cn(`w-full grid gap-4`, ['grid-cols-1', 'grid-cols-2', 'grid-cols-3', 'grid-cols-4'][columns - 1])}>
      {tiles.map((t) => (
        <CategoryTile
          key={t.title}
          tile={t}
        />
      ))}
    </div>
  );
};

// This component renders a tile, resolving the image and attaching click handlers
const CategoryTile = ({tile}: {tile: CategoryConfig}) => {
  const {title, imageHash, icon, onClick, buildingId} = tile;
  const image = useRoomImage(title ?? '', imageHash ?? undefined, buildingId, 250, true);

  return (
    <div
      onClick={onClick}
      className={cn(
        'group relative rounded-lg bg-slate-100 flex flex-col gap-2',
        'justify-between overflow-hidden',
        'bg-cover bg-center text-white hover:cursor-pointer',
        'p-4 h-[180px]',
      )}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7)), url(${image})`,
      }}>
      <div className="bg-white rounded-lg size-12 shadow-mapiq-small flex items-center justify-center flex-none">
        <Icon
          icon={icon}
          size={'24px'}
        />
      </div>
      <div className="truncate transition-transform group-hover:-translate-y-1">{title}</div>
    </div>
  );
};
