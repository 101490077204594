import {useBreakPoint, useModal} from '@hooks';
import {FlexCol, H3, H4, Subtitle} from '@quarks';
import {Avatar} from '@molecules';
import {Icon} from '@atoms';
import {ConnectionsLoader} from './ConnectionsLoader';
import {ConnectionUserDto, WorkspaceReservationDto} from '@lib/infrastructure';
import {cn} from '@utils';
import {ConnectionOnMapModal} from './ConnectionOnMapModal';
import {NoConnections, NoOneThere} from './EmptyState';
import {useRelevantConnections} from './useRelevantConnections';

export const ConnectionOverview = (props: {dateKey: string}) => {
  const bp = useBreakPoint();
  const {setModalPages, setBigPages, openModal} = useModal();

  const {status, connections, buildingName} = useRelevantConnections(props.dateKey);

  const showConnectionOnMap = (user: ConnectionUserDto, workspace: WorkspaceReservationDto) => {
    const mapView = (
      <ConnectionOnMapModal
        user={user}
        workspace={workspace}
      />
    );

    setModalPages([mapView]);
    setBigPages([mapView]);
    openModal();
  };

  if (status === 'Loading') return <ConnectionsLoader />;

  return (
    <FlexCol gap={16}>
      <H3 className="pl-0">Your connections</H3>

      {status === 'UserHasNoConnections' ? (
        <NoConnections />
      ) : (
        <div className={cn('grid full-w gap-6 grid-cols-1', {'grid-cols-2': bp === 'medium' || bp === 'large'})}>
          <H4 className="pl-0">{buildingName}</H4>
          {status === 'Done' ? (
            connections.map(({user, workspace}) => (
              <div
                className="flex w-full gap-2 items-center"
                key={user.id}>
                <Avatar
                  className="flex-none"
                  user={{
                    email: user.email,
                    initials: user.initials,
                    name: user.name,
                    displayName: user.name,
                    id: user.id,
                    inviteResponse: undefined,
                    imageHash: user.imageHash,
                  }}
                />
                <div className="overflow-hidden mr-auto">
                  <p className="truncate">{user.name}</p>
                  {workspace && workspace.floorHasMap ? (
                    <Subtitle className="truncate">{`${workspace.floorName}, ${workspace.areaName}`}</Subtitle>
                  ) : null}
                </div>
                {workspace ? (
                  <button
                    className="flex-none"
                    aria-label="Show on map"
                    onClick={() => {
                      showConnectionOnMap(user, workspace);
                    }}>
                    <Icon
                      icon="map"
                      size="20px"
                      color="#767676"
                    />
                  </button>
                ) : null}
              </div>
            ))
          ) : (
            <NoOneThere />
          )}
        </div>
      )}
    </FlexCol>
  );
};
