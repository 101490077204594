import {useBreakPoint} from '@hooks';
import {PropsWithChildren} from 'react';
import styled from 'styled-components';

export const Tile = ({
  children,
  title,
  viewAllAction,
}: PropsWithChildren<{title?: string; viewAllAction?: () => void}>) => {
  const breakpoint = useBreakPoint();
  const cardStyling = breakpoint === 'small' ? '' : 'rounded-lg shadow-md';

  return (
    <div className={`flex p-6 flex-col items-start gap-6 bg-white ${cardStyling}`}>
      {title && (
        <h3 className="flex justify-between w-full">
          <span>{title}</span>

          {viewAllAction && (
            <button
              className="text-collaborative-blue-700 text-sm"
              onClick={viewAllAction}>
              View all
            </button>
          )}
        </h3>
      )}
      {children}
    </div>
  );
};

const defaultRows = [[320], [64]];

export const LoaderTile = ({rows = defaultRows}) => {
  return (
    <Tile>
      <div className="animate-pulse rounded-md bg-slate-100 w-2/3 h-6"></div>

      {rows.map((row, r) => (
        <div
          key={r}
          className="flex gap-4 w-full">
          {row.map((height, c) => (
            <StyledPulseBlock
              $delay={(1 + r + c) * 300}
              $height={height}
              key={c}
              className={`flex-grow animate-pulse rounded-md bg-slate-100`}
              style={{}}
            />
          ))}
        </div>
      ))}
    </Tile>
  );
};

const StyledPulseBlock = styled.div<{$height: number; $delay: number}>`
  // Note: using styled component here because struggling with tailwind dynamic class names
  height: ${({$height}) => `${$height}px !important`};
  animation-delay: ${({$delay}) => `${$delay}ms !important`};
  transition-delay: ${({$delay}) => `${$delay}ms !important`};
`;
