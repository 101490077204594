import {useTranslation} from 'react-i18next';
import {useState, useEffect} from 'react';

import {Icon} from '@atoms';
import {useAppDispatch, useAppSelector, useModal} from '@hooks';
import {
  getIsLoadingUserCheckIn,
  getParkingReservationByDate,
  getStartOfTodayISOString,
  getUserCheckInStatus,
  getWorkdayByDate,
  getWorkspaceReservationByDate,
  trackEvent,
  updateCheckInStatusNew,
  withAsyncThunkErrorHandling,
  getMapDataByFloorId,
  getParkingSettings,
  getIsUserAllowedToReserveParking,
  getLicensePlates,
  loadParkingLotsByBuilding,
  loadParkingCount,
  getIsLoadingParkingLots,
  getCanShowCheckInTile,
  getConnectedUsersByDate,
} from '@lib/store';
import {Avatar, AvatarStack, Button, MapView} from '@molecules';
import {BookingDetailsCard, BookingLocationStatusCard, LoaderTile, ParkingSelectionCard, Tile} from '@organisms';
import {FlexCol, FlexRow, H4, P} from '@quarks';
import {CheckInEventType, CheckInSourceType} from '@lib/infrastructure';

import {format, isToday, parseISO} from 'date-fns';
import {FacilityMarkerData} from '@map/mapiq-map/markers/NodeMarkerData';
import {LicensePlatesModalPage} from '../../profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';
import {ParkingSkeletonLoader} from './ParkingSkeletonLoader';
import {useFacilityMarkers} from '../../hereAndNow/useFacilityMarkers';

export const WorkdayTile = ({date}: {date: Date}) => {
  const [expanded, setExpanded] = useState(false);

  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {addPages, openModal, setModalPages} = useModal();

  const dateKey = format(date, 'yyyy-MM-dd');
  const workday = useAppSelector((state) => getWorkdayByDate(state, dateKey)) ?? null;
  const todayISOString = useAppSelector(getStartOfTodayISOString);
  const canShowCheckInTile = useAppSelector((state) => getCanShowCheckInTile(state, dateKey));
  const buildingId = workday?.nodeId;
  const workdayLoading = useAppSelector((state) => state.data.workday.isLoading);
  const isLoadingParkingLots = useAppSelector(getIsLoadingParkingLots);
  const workspaceReservation = useAppSelector((state) => getWorkspaceReservationByDate(state, date)) ?? null;
  const workspaceReservationLoading = useAppSelector((state) => state.data.workspaceReservations.isLoading);
  const parkingReservation = useAppSelector((state) => getParkingReservationByDate(state, dateKey)) ?? null;
  const isLoadingCheckInStatus = useAppSelector(getIsLoadingUserCheckIn);
  const {eventType: checkInEventType, buildingId: checkInBuildingId} = useAppSelector(getUserCheckInStatus);
  const isOfficeDay = workday?.status === 'OfficeDay';
  const isWorkspaceReservationToday = isToday(parseISO(todayISOString));
  const {status: mapStatus} = useAppSelector(
    getMapDataByFloorId(buildingId ?? '', workspaceReservation ? workspaceReservation.floorId : '', '2d'),
  );
  const parkingSettings = useAppSelector(getParkingSettings);
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    getIsUserAllowedToReserveParking(state, parseISO(dateKey)),
  );
  const licensePlates = useAppSelector(getLicensePlates);
  const facilityMarkers = useFacilityMarkers(
    workspaceReservation?.buildingId ?? null,
    workspaceReservation?.floorId ?? null,
  );
  const connections = useAppSelector((state) =>
    getConnectedUsersByDate(state, {
      date: dateKey,
      nodeId: workspaceReservation?.areaId ?? workspaceReservation?.floorId,
    }),
  );

  const checkInDisabled =
    checkInEventType === CheckInEventType.NotAtTheOffice && (!workday || workday.status !== 'OfficeDay');
  const isNotCheckedInYet = checkInEventType === CheckInEventType.NotCheckedInYet;
  const isNotAtTheOffice = checkInEventType === CheckInEventType.NotAtTheOffice;
  const CheckInButtonTitle = isNotCheckedInYet || isNotAtTheOffice ? t('workplace:CheckIn') : t('workplace:CheckOut');

  const mapMarker: FacilityMarkerData = {
    type: 'facility',
    subType: workspaceReservation?.deskId ? 'MeetingRoom' : 'Area',
    nodeId: workspaceReservation?.deskId ? workspaceReservation?.deskId : workspaceReservation?.areaId ?? '',
  };

  const topRowIcon =
    workday?.status === 'OfficeDay' ? 'office' : workday?.status === 'NotWorking' ? 'calendarX' : 'remote';
  const topRowHeader = workday?.nodeName
    ? workday.nodeName
    : workday?.status === 'NotWorking'
    ? t('workplace:NotWorking')
    : t('workplace:WorkingRemotely');

  const showCollapse = (workspaceReservation || isUserAllowedToReserveParking) && isOfficeDay;

  // Parking
  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;
  const isParkingDisabled = !isUserAllowedToReserveParking && !parkingReservation;

  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;

  const parkingUI = isLoadingParkingLots ? (
    <ParkingSkeletonLoader />
  ) : (
    <FlexRow
      alignItems="center"
      justifyContent="space-between"
      gap={16}>
      <FlexRow
        gap={16}
        alignItems="center">
        <FlexCol
          justifyContent="center"
          alignItems="center"
          className=" bg-beige-500* h-12 w-12 rounded-lg">
          <Icon
            icon="parking"
            size="24px"
          />
        </FlexCol>
        <FlexCol>
          <H4>{parkingReservation?.parkingLotName ?? t('parking:ParkingReservationButtonText')}</H4>
          <P>{parkingReservation?.zoneName}</P>
        </FlexCol>
      </FlexRow>
      <Button
        button={'tertiary'}
        disabled={isParkingDisabled}
        onClick={handleClickChangeParking}>
        {parkingReservation ? t('Change') : t('Book')}
      </Button>
    </FlexRow>
  );

  const workspaceLabel = workspaceReservation
    ? [workspaceReservation.floorName, workspaceReservation.areaName, workspaceReservation.deskName]
        .filter((x) => x)
        .join(', ')
    : 'No workspace reservation';

  useEffect(() => {
    if (buildingId) {
      dispatch(
        withAsyncThunkErrorHandling(() =>
          loadParkingLotsByBuilding({
            buildingId,
          }),
        ),
      );
      dispatch(withAsyncThunkErrorHandling(() => loadParkingCount({selectedDate: dateKey})));
    }
  }, [dispatch, buildingId, date, dateKey]);

  function handleClickChangeParking() {
    if (isParkingDisabled) return;

    if (shouldOpenLicensePlateModal) {
      setModalPages([<LicensePlatesModalPage date={dateKey} />]);
    } else {
      setModalPages([<ParkingSelectionCard date={dateKey} />]);
    }

    openModal();
  }

  function handleUpdateCheckInStatus(status: CheckInEventType) {
    dispatch(
      withAsyncThunkErrorHandling(() =>
        updateCheckInStatusNew({
          buildingId: status === CheckInEventType.AtTheOffice ? workday?.nodeId : checkInBuildingId,
          eventType: status,
          sourceType: CheckInSourceType.Manual,
        }),
      ),
    );
  }

  function updateToNotAtTheOffice() {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    dispatch(trackEvent('EditManualCheckIn'));
    handleUpdateCheckInStatus(CheckInEventType.NotAtTheOffice);
  }

  function updateToAtTheOffice() {
    if (isLoadingCheckInStatus || checkInDisabled) return;
    setExpanded(false);
    checkInEventType === CheckInEventType.NotCheckedInYet
      ? dispatch(trackEvent('CheckInManually', {isShiftDay: isWorkspaceReservationToday}))
      : dispatch(trackEvent('EditManualCheckIn'));

    handleUpdateCheckInStatus(CheckInEventType.AtTheOffice);
  }

  function handleClickWorkspaceInformationButton() {
    if (workspaceReservation) {
      addPages([
        <BookingDetailsCard
          date={dateKey}
          workspaceReservation={workspaceReservation}
        />,
      ]);
      openModal();
    }
  }

  return workdayLoading && workspaceReservationLoading ? (
    <LoaderTile />
  ) : (
    <Tile>
      <FlexCol
        gap={16}
        width="100%">
        <FlexRow alignItems="center">
          {/* Icon - Office Name */}
          <FlexRow
            justifyContent="center"
            alignItems="center"
            gap={16}>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              className=" bg-energizing-yellow-500* h-12 w-12 rounded-lg">
              <Icon
                icon={topRowIcon}
                size="24px"
              />
            </FlexCol>
            <H4>{topRowHeader}</H4>
          </FlexRow>

          {/* Check In - Change Buttons */}
          <FlexRow
            alignItems="center"
            gap={16}>
            {/* Parking / Desk Icons */}
            <FlexRow gap={16}>
              {workspaceReservation ? (
                <Icon
                  icon="desk"
                  size="24px"
                />
              ) : null}
              {isUserAllowedToReserveParking && isOfficeDay ? (
                <Icon
                  icon="parking"
                  size="24px"
                />
              ) : null}
            </FlexRow>
            <FlexRow gap={8}>
              <Button
                onClick={() => {
                  addPages([<BookingLocationStatusCard date={dateKey} />]);
                  openModal();
                }}
                button="tertiary">
                {t('Change')}
              </Button>
              {canShowCheckInTile && workday?.status === 'OfficeDay' ? (
                <Button
                  button={checkInEventType === CheckInEventType.AtTheOffice ? 'secondary' : 'primary'}
                  onClick={
                    checkInEventType === CheckInEventType.AtTheOffice ? updateToNotAtTheOffice : updateToAtTheOffice
                  }>
                  {CheckInButtonTitle}
                </Button>
              ) : null}
            </FlexRow>
            {showCollapse ? (
              <Icon
                icon={expanded ? 'caretUp' : 'caretDown'}
                size="24px"
                className="cursor-pointer"
                onClick={() => setExpanded((prev) => !prev)}
              />
            ) : null}
            {/* Collapse Chevron */}
          </FlexRow>
        </FlexRow>
        {expanded ? (
          <FlexCol gap={16}>
            {workspaceReservation && buildingId ? (
              <>
                <FlexRow>
                  <MapView
                    buildingId={buildingId}
                    floorId={workspaceReservation.floorId}
                    highlights={[...facilityMarkers, mapMarker]}
                    buildingNodeStates={new Map([[mapMarker.nodeId, 'selected']])}
                    buildingNodeTypeStates={new Map([['facility', 'dimmed']])}
                    fullView={false}
                    disablePointerEvents={true}
                  />
                </FlexRow>
                <FlexRow
                  alignItems="center"
                  gap={16}>
                  <FlexRow
                    gap={16}
                    alignItems="center">
                    <FlexCol
                      justifyContent="center"
                      alignItems="center"
                      className="bg-beige-500* h-12 w-12 rounded-lg">
                      <Icon
                        icon="desk"
                        size="24px"
                      />
                    </FlexCol>
                    <FlexCol>
                      <H4>{workspaceReservation.areaName}</H4>
                      <P>{workspaceLabel}</P>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow
                    alignItems="center"
                    gap={16}>
                    {connections.length === 1 ? <Avatar user={connections[0]} /> : null}
                    {connections.length > 1 ? (
                      <AvatarStack
                        date={dateKey}
                        users={connections}
                        overlap={true}
                      />
                    ) : null}
                    <Button
                      onClick={handleClickWorkspaceInformationButton}
                      button={'tertiary'}
                      className="p-2">
                      <Icon
                        icon="info"
                        size="20px"
                      />
                    </Button>
                  </FlexRow>
                </FlexRow>
              </>
            ) : null}
            {isUserAllowedToReserveParking && isOfficeDay ? parkingUI : null}
          </FlexCol>
        ) : null}
      </FlexCol>
    </Tile>
  );
};
