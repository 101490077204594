import {useAppSelector} from '@hooks';
import {getFutureWorkdaysWithExtraInformation} from '@lib/store';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {LoaderTile, Tile} from '../Tile';
import {FlexCol, FlexRow, H4, P} from '@quarks';
import {Icon} from '@atoms';
import {toISODateString} from '@lib/utils';
import {Avatar, AvatarStack, Button} from '@molecules';

type Props = {
  date: Date;
};

export const NextWorkdaysTile = ({date}: Props) => {
  const {t} = useTranslation();

  const dateKey = format(date, 'yyyy-MM-dd');
  const workdayLoading = useAppSelector((state) => state.data.workday.isLoading);
  const futureWorkdaysWithConnections = useAppSelector((state) =>
    getFutureWorkdaysWithExtraInformation(state, toISODateString(date)),
  );

  return workdayLoading ? (
    <LoaderTile />
  ) : (
    <Tile title="Your next office days">
      {futureWorkdaysWithConnections.length ? (
        futureWorkdaysWithConnections.map(({id, nodeName, connections, parking, workspaceReservation, startDate}) => {
          return (
            <FlexRow
              gap={24}
              justifyContent="space-between"
              alignItems="center"
              key={id}
              className="w-full">
              <FlexRow
                gap={16}
                alignItems="center">
                <FlexCol
                  justifyContent="center"
                  alignItems="center"
                  className=" bg-energizing-yellow-500* h-12 w-12 rounded-lg">
                  <Icon
                    icon="office"
                    size="24px"
                  />
                </FlexCol>
                <FlexCol justifyContent="center">
                  <H4>{format(new Date(startDate), 'EEEE d MMMM')}</H4>
                  <P>{nodeName}</P>
                </FlexCol>
              </FlexRow>
              <FlexRow gap={16}>
                <FlexRow
                  alignItems="center"
                  gap={16}>
                  {connections.length === 1 ? (
                    <Avatar user={connections[0]} />
                  ) : connections.length > 1 ? (
                    <AvatarStack
                      users={connections}
                      date={dateKey}
                      overlap={true}
                    />
                  ) : null}

                  {workspaceReservation ? (
                    <Icon
                      icon="desk"
                      size="24px"
                    />
                  ) : null}
                  {parking ? (
                    <Icon
                      icon="parking"
                      size="24px"
                    />
                  ) : null}
                </FlexRow>
                <a href={`/${format(new Date(startDate), 'yyyy-MM-dd')}`}>
                  <FlexCol
                    justifyContent="center"
                    alignItems="center"
                    className="p-[10px] bg-mapiq-black-50 rounded-lg cursor-pointer">
                    <Icon
                      icon="caretRight"
                      size="24px"
                    />
                  </FlexCol>
                </a>
              </FlexRow>
            </FlexRow>
          );
        })
      ) : (
        <FlexCol
          justifyContent="center"
          alignItems="center"
          className="h-64 w-full bg-energizing-yellow-500* rounded-lg"
          gap={16}>
          <FlexCol
            justifyContent="center"
            alignItems="center">
            <H4>You don’t have any office days planned</H4>
            <P>Make sure you always have the perfect meeting room for your next meeting</P>
          </FlexCol>
          <Button button={'primary light'}>Plan your next office days</Button>
        </FlexCol>
      )}
    </Tile>
  );
};
