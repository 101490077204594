import {FeatureToggle, getEnabledFeatures} from '@lib/store';
import {useAppSelector} from './useAppSelector';

type FeatureName = keyof typeof FeatureToggle;

// For debug purposes, store reference to search params at initial load
const localOverrides = createLocalOverrides(document.location.search);

export const useFeatureToggle = () => {
  const enabledFeatures = new Set(useAppSelector(getEnabledFeatures));
  const features = Object.keys(FeatureToggle) as Array<FeatureName>;

  // Apply local overrides for debug and  testing purposes
  for (const [name, enabled] of localOverrides) {
    if (enabled) {
      enabledFeatures.add(FeatureToggle[name]);
    } else {
      enabledFeatures.delete(FeatureToggle[name]);
    }
  }

  const featureObject: Partial<Record<FeatureName, boolean>> = features.reduce(
    (prevFeature, featureName) => ({
      ...prevFeature,
      [featureName]: enabledFeatures.has(FeatureToggle[featureName]),
    }),
    {},
  );

  return featureObject;
};

function createLocalOverrides(query: string) {
  const params = new URLSearchParams(query);
  const overrides = new Map<FeatureName, boolean>();

  for (const featureName in FeatureToggle) {
    const override = params.get(featureName);

    if (override === '1') overrides.set(featureName as FeatureName, true);
    if (override === '0') overrides.set(featureName as FeatureName, false);
  }

  return overrides;
}
